import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, Divider, Grid, TablePagination, Tooltip, TableSortLabel, } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, TextField, } from '@mui/material';
// import { Link as MaterialLink } from "@mui/material";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom"
// import { Link as ReactLink, useNavigate } from "react-router-dom"
import axios from 'axios';
import CollectTextDialog from "../zujian/collectTextDialog"
import { MyContext } from "../context";
import Copyright from '../copyright';
import SuoyouGuanliyuan from './suoyouGuanliyuan';
const quanjuBianliang = require('../changliang/quanju');
export default function SuoyouDingdan() {

  const dataTitle = '订单管理';
  //后台数据访问接口
  const backDataUrl = '/api/guanliyuan/suoyouDingdan';
  const querenJigouDingdanUrl = '/api/guanliyuan/querenJigouDingdan';
  // 取得订单导出 excel 的 Zip 文件名 API 
  const downloadCepingYifukuanDingdanUrl = '/api/dingdan/getCepingDingdanExcelFile';

  const myContext = React.useContext(MyContext);
  const { guanliYonghuId, setDengluFlag, buxuanJigou, xingming, chaojiFlag, setChaojiFlag, chaojiTag } = myContext;
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);

  const [shujuList, setShujuList] = useState([]);
  const [showWhichDialog, setShowWhichDialog] = useState('');

  //触发页面数据刷新的状态
  const [shuaxinCount, setShuaxinCount] = useState(0);

  //增加筛选条件，测评列表、测评参与机构列表、测评参与科目等级列表
  const cepingListUrl = '/api/common/getCepingList';
  const yixuanJigouListUrl = '/api/guanliyuan/getYixuanJigouList';
  const yigouKemuListUrl = '/api/guanliyuan/getYigouKemuList';

  const [cepingId, setCepingId] = useState('');
  const [yixuanJigou, setYixuanJigou] = useState('');
  const [cepingList, setCepingList] = useState([]);
  const [yixuanJigouList, setYixuanJigouList] = useState([]);
  const [yigouKemuDengjiList, setYigouKemuDengjiList] = useState([]);

  const [orderDirection, setOrderDirection] = useState('desc');

  const [tjKaishiRiqi, setTjKaishiRiqi] = useState('');
  const [tjJieshuRiqi, setTjJieshuRiqi] = useState('');
  const [tjDingdanId, setTjDingdanId] = useState('');
  const [tjShoujiHaoma, setTjShoujiHaoma] = useState('');
  const [tjXingming, setTjXingming] = useState('');
  const [tjDingdanZhuangtai, setTjDingdanZhuangtai] = useState('');
  const [tjKemuDengji, setTjKemuDengji] = useState('');
  const [tjChengjiDengji, setTjChengjiDengji] = useState('');
  const [cepingDingdanExcelUrl,setCepingDingdanExcelUrl] = useState('');
  // 下载订单Excel链接的显示文字：
  const [downloadDingdanExcelLinkShowText, setDownloadDingdanExcelLinkShowText] = useState(''); 
  // 产生订单Excel文件的按钮，防止快速重复点击
  const [buttonIsDisable, setButtonIsDisable] = useState(false); 
  


  useEffect(() => {

    axios.post(quanjuBianliang.houtaiLujing + cepingListUrl,
      { withCredentials: true })
      .then((response) => {
        if (response.data.status === 1) {
          // console.log('确认测评列表成功！');
          setCepingList(response.data.shuju);
          // console.log('---------');
          // console.log(response.data.shuju);
        } else {
          console.log('服务器错误:' + response.data.message);
        }
      }, (err) => {
        console.log(err);
      });

  }, []);

  useEffect(() => {
    //获取本次测评的参与机构列表、参与科目等级列表; 如果测评ID为空,列表只有一个所有.
    const fetchData = async () => {
      try {
        if (cepingId.length > 0) {
          let kexuanJigouListResult = await axios.post(quanjuBianliang.houtaiLujing + yixuanJigouListUrl,
            { cepingId }, { withCredentials: true });
          if (kexuanJigouListResult.data.status === -1) { setDengluFlag(false); navigate("/backend/denglu"); };
          if (kexuanJigouListResult.data.status === 1)
            setYixuanJigouList(['所有机构'].concat(kexuanJigouListResult.data.shuju.map(item => item.id)));

          let yigouKemuListResult = await axios.post(quanjuBianliang.houtaiLujing + yigouKemuListUrl,
            { cepingId }, { withCredentials: true });
          if (yigouKemuListResult.data.status === 1) {
            let tmpKemuDengjiList = [''];
            let yigouKemuList = yigouKemuListResult.data.shuju;
            yigouKemuList.forEach(km => {
              for (var i = 0; i < km.dengjiShuliang; i++) tmpKemuDengjiList.push(km.kemuMingcheng + '##' + i);
            });
            setYigouKemuDengjiList(tmpKemuDengjiList);
          }

        } else {
          setYixuanJigouList(['所有机构']);
          setYigouKemuDengjiList(['']);
        }
      } catch (err) { console.log(err); }
    };
    fetchData();

    /*/
    axios.post(quanjuBianliang.houtaiLujing + yixuanJigouListUrl,
      { cepingId }, { withCredentials: true })
      .then((response) => {
        if (response.data.status === 1) {
          // console.log('确认已选机构清单成功！');
          setYixuanJigouList([{ "id": "所有机构" }].concat(response.data.shuju));
          // console.log('*******');
          // console.log(response.data.shuju);
        } else if (response.data.status === -1) {
          // console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
      }, (err) => {
        console.log(err);
      });
    //*/
  }, [cepingId]);

  //将科目等级的内部表示，转换为显示字符串
  function getKemuDengjiShowStr(kemuDengjiStr) {
    if (kemuDengjiStr.length < 1) return '所有科目等级';
    else {
      let kemuDengjiInfo = kemuDengjiStr.split('##');
      if (kemuDengjiInfo.length > 1) {
        return kemuDengjiInfo[0] + (parseInt(kemuDengjiInfo[1]) + 1) + '级';
      } else return kemuDengjiInfo;
    }
  }

  const getDingdanZhuangtai = (item) => {
    return (item.xuehuiZhifuZhuangtai || item.alipayZhuangtai) ? '已完成' :
      item.jigouFukuanFlag ?
        (item.jigouFukuanXinxi && item.jigouFukuanXinxi.length > 2 ?
          '机构已确认'
          : '待机构确认')
        : '未完成'
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const querenJigouDingdan = (item, beizhu) => {
    console.log('---调用确认机构订单后台----');
    //请求后台，更新订单机构付款信息备注
    axios.post(quanjuBianliang.houtaiLujing + querenJigouDingdanUrl,
      {
        dingdanId: item.dingdanId,
        dingdanBeizhu: xingming + '(' + guanliYonghuId + '),' + getFormattedDate() + ',' + beizhu + ';'
      },
      { withCredentials: true })
      .then((response) => {
        if (response.data.status === 1) {
          console.log('确认机构订单信息成功！');
          setShuaxinCount((pre) => pre + 1);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }
  function getFormattedDate() {
    var date = new Date();
    var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    return str;
  }
  function qingkongTiaojian() {
    setCepingId(''); setYixuanJigou(''); setTjKaishiRiqi(''); setTjJieshuRiqi('');
    setTjDingdanId(''); setTjShoujiHaoma(''); setTjXingming(''); setTjDingdanZhuangtai('');
    setTjKemuDengji(''); setTjChengjiDengji('');
  }

  //加载我的订单
  useEffect(() => {
    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      {
        page, rowsPerPage, cepingId,
        yixuanJigou: yixuanJigou === '所有机构' ? '' : yixuanJigou,
        //新增的8个查询条件
        tjKaishiRiqi, tjJieshuRiqi, tjDingdanId, tjShoujiHaoma, tjXingming, tjDingdanZhuangtai, tjKemuDengji, tjChengjiDengji,
        //end 新增
        orderDirection,
      },
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          console.log('获取' + dataTitle + ' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page, rowsPerPage, shuaxinCount, cepingId, orderDirection]); //yixuanJigou, 

  //-- 取得订单excel 导出的文件名，或者 将已付款订单导出 excel 文件，用于放置链接提供下载----
  const downloadCepingYifukuanDingdan = () => {
    //测评活动Id存在才导出
    if (cepingId.length > 2) {
      const fetchData = async () => {
        try {
          setDownloadDingdanExcelLinkShowText('正在导出订单excel文件，稍候几秒...... ');
          let downloadCepingDingdanResponse = await axios.post(quanjuBianliang.houtaiLujing + downloadCepingYifukuanDingdanUrl,
            { cepingHuodongId: cepingId }, { withCredentials: true });
          //检查登录状态，如果未登录，跳转到登录页面
          if (downloadCepingDingdanResponse.data.status === -1) { setDengluFlag(false); navigate("/backend/denglu"); };
          let downloadFileName = downloadCepingDingdanResponse.data.excelFileName;          
          if(downloadCepingDingdanResponse.data.status === -4){  // 如果查到订单数为零，就显示服务器的返回的文字信息“ 订单数为零 ”
            setDownloadDingdanExcelLinkShowText(downloadCepingDingdanResponse.data.message);
            setButtonIsDisable(false);  // 设置 “导出excel” 的按钮重新有效。
            return;
          }
          if(chaojiTag === true) {  // 如果当前登录账号是超级管理员，才允许下载
            setCepingDingdanExcelUrl('#');    
            setCepingDingdanExcelUrl('/dingdanExcelFiles/'+ downloadFileName);    
            setDownloadDingdanExcelLinkShowText('下载最近一次导出的已付款订单 Excel 文件：' + downloadFileName);      
            // window.open('/'+downloadFileName,'_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');

          }

          //自动生成下载地址，并进行下载
          // var link = document.createElement("a");
          // link.href = quanjuBianliang.houtaiLujing + downloadFileName;
          // link.download = downloadFileName;

          // document.body.appendChild(link);

          // link.click();
          // setTimeout(function () {
          //   window.URL.revokeObjectURL(link);
          // }, 200);

        } catch (err) { console.log(err); }
      }
      fetchData();
    }
    
  }

  // getDingdanExcelZipFileName(); // 执行取订单导出的 excel zip 文件名


  //-----------------
  const getKexuanInfo = (kexuanLiebiaoStr) => {
    var result = '';
    var kexuanInfo = kexuanLiebiaoStr.split(';');
    kexuanInfo.pop();
    kexuanInfo.forEach((item) => {
      var kemuInfo = item.split('##');
      result += kemuInfo[0] + (parseInt(kemuInfo[1]) + 1) + '级,';
      //gengxinKemuFlag(idx,true,parseInt(kemuInfo[1]));
    });
    return result;
  }

  function appendLeadingZeroes(n) {
    if (n <= 9) { return "0" + n; }
    return n
  }
  const getLocalDate = (dateInfo) => {
    let current_datetime = new Date(dateInfo);
    let formatted_date = current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + appendLeadingZeroes(current_datetime.getDate()) + " " + appendLeadingZeroes(current_datetime.getHours()) + ":" + appendLeadingZeroes(current_datetime.getMinutes()) + ":" + appendLeadingZeroes(current_datetime.getSeconds());
    return formatted_date;
  }

  function bishiFenshuDengji(item) {
    if (!item.bishiFenshu || item.bishiFenshu.length < 1) return '';
    let fenshu = parseInt(item.bishiFenshu);
    if (fenshu < 60) return '再接再厉';
    else if (fenshu < 90) return '合格';
    else return '优秀';
  }

  // 产生订单Excel文件的按钮，防止快速重复点击  
  function isRepeatClick(tmp_cepingId){
    if(tmp_cepingId < 2){
      setDownloadDingdanExcelLinkShowText('* 必须先选择测评活动才能导出订单的 Excel文件');
      return;
    }else{
      downloadCepingYifukuanDingdan();  // 执行产生 订单Excel 下载链接
      setButtonIsDisable(true);  // 设置产生 excel 的按钮无效
      setTimeout(()=> {setButtonIsDisable(false)}  ,5000)  //延迟几秒后，设置产生 excel 的按钮有效
    }    
  }


  // console.log("超级管理员标记 chaojiTag = ", chaojiTag);
  
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 4 }}>
        <Typography
          variant="h6"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>
        <Box component="span"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 2, px: 2, my: 2,
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: 2,
          }}>
          <Grid container>
            <Grid item xs={2} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">测评活动</InputLabel>
                <Select
                  label='测评活动'
                  labelId="cepingId"
                  id="cepingId"
                  value={cepingId}
                  onChange={(e) => { setCepingId(e.target.value); setPage(0) }}
                >
                  {cepingList && cepingList.map((item) => {
                    return (
                      <MenuItem key={item.cepingId} value={item.cepingId}>{item.cepingMingcheng}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'row', mx: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">选择机构</InputLabel>
                <Select
                  label='选择机构'
                  labelId="yixuanJigou"
                  id="yixuanJigou"
                  value={yixuanJigou}
                  onChange={(e) => { setYixuanJigou(e.target.value); setPage(0); }}
                >
                  {yixuanJigouList && yixuanJigouList.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">选择科目等级</InputLabel>
                <Select
                  label='选择科目等级'
                  labelId="kemuDengji"
                  id="kemuDengji"
                  value={tjKemuDengji}
                  onChange={(e) => { setTjKemuDengji(e.target.value); setPage(0); }}
                >
                  {yigouKemuDengjiList && yigouKemuDengjiList.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>{getKemuDengjiShowStr(item)}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ mx: 1 }}>
              <TextField
                sx={{ width: '100%' }}
                id="date2"
                label="开始日期"
                type="date"
                value={tjKaishiRiqi}
                onChange={(e) => { setTjKaishiRiqi(e.target.value) }}
                size="small"
                InputLabelProps={{ shrink: true, }}
              />
            </Grid>
            <Grid item xs={2} >
              <TextField
                sx={{ width: '100%' }}
                id="date1"
                label="结束日期"
                type="date"
                value={tjJieshuRiqi}
                onChange={(e) => { setTjJieshuRiqi(e.target.value) }}
                size="small"
                InputLabelProps={{ shrink: true, }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={3} >
              <TextField size='small' sx={{ mb: 1, width: '100%', }} variant="outlined"
                value={tjDingdanId} onChange={(e) => setTjDingdanId(e.target.value)} label="订单号"
              />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', mx: 1 }}>
              <TextField size='small' sx={{ mb: 1, width: '100%', }} variant="outlined"
                value={tjShoujiHaoma} onChange={(e) => setTjShoujiHaoma(e.target.value)} label="手机号码"
              />
            </Grid>
            <Grid item xs={2} sx={{ mr: 1 }}>
              <TextField size='small' sx={{ mb: 1, width: '100%' }} variant="outlined"
                value={tjXingming} onChange={(e) => setTjXingming(e.target.value)} label="姓名"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-simple-select-label">订单状态</InputLabel>
                <Select
                  labelId="dingdanZhuangtai"
                  id="demo-simple-select"
                  value={tjDingdanZhuangtai}
                  label="订单状态"
                  onChange={(e) => setTjDingdanZhuangtai(e.target.value)}
                >
                  <MenuItem value={''}>{'所有订单'}</MenuItem>
                  <MenuItem value={'机构未完成'}>{'机构未完成'}</MenuItem>
                  <MenuItem value={'机构已确认'}>{'机构已确认'}</MenuItem>
                  <MenuItem value={'自助完成'}>{'自助支付完成'}</MenuItem>
                  <MenuItem value={'自助未完成'}>{'自助支付未完成'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ mx: 1 }}>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-simple-select-label">成绩等级</InputLabel>
                <Select
                  labelId="chengjiDengji"
                  id="chengjiDengji"
                  value={tjChengjiDengji}
                  label="成绩等级"
                  onChange={(e) => setTjChengjiDengji(e.target.value)}
                >
                  <MenuItem value={''}>{'所有'}</MenuItem>
                  <MenuItem value={'合格'}>{'合格'}</MenuItem>
                  <MenuItem value={'优秀'}>{'优秀'}</MenuItem>
                  <MenuItem value={'不合格'}>{'不合格'}</MenuItem>
                  <MenuItem value={'无成绩'}>{'无成绩'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={9} sx={{ mx: 0, mt: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button variant='outlined' sx={{ mb: 1 }} size='small' onClick={qingkongTiaojian}> 清空所有查询条件 </Button>
            <Button variant='outlined' sx={{ mb: 1, ml: 4 }} size='small' onClick={() => { setShuaxinCount((pre) => pre + 1); setPage(0) }}> 查询结果</Button>
            { chaojiTag === true &&   
            <Button variant='outlined' sx={{ mb: 1, ml: 4 }} size='small' onClick={()=>{
                  isRepeatClick(cepingId);
                  }}  disabled={buttonIsDisable} > 按测评活动导出已付款订单</Button> }
            { chaojiTag === true && 
            <Typography variant='subtitle' sx={{ml:3}} > 
               <Link href={cepingDingdanExcelUrl ? cepingDingdanExcelUrl: '#' } underline="always" rel="noopener"  
                  color='error.main'  id = 'excelDownloadLink' 
                > 
                  {downloadDingdanExcelLinkShowText}
                </Link>
            </Typography> }
            
          </Grid> 
        </Box>
        <TableContainer component={Paper} sx={{ width: 'max-content' }}>
          <Table size='small' sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">订单号</TableCell>
                <TableCell align="center">姓名</TableCell>
                <TableCell align="center">手机号码</TableCell>
                <TableCell align="center">身份证号码</TableCell>

                <TableCell align="center">所选机构</TableCell>
                <TableCell align="center">
                  <TableSortLabel active={true} direction={orderDirection}
                    onClick={() => orderDirection === 'desc' ? setOrderDirection('asc') : setOrderDirection('desc')}>
                    订单时间
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">科目等级</TableCell>
                <TableCell align="center">金额</TableCell>
                <TableCell align="center">订单状态</TableCell>
                <TableCell align="center">成绩报告</TableCell>
                <TableCell align="center">等级证书</TableCell>

              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  <TableCell component="th" scope="row">
                    {item.dingdanId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.xueyuan.xingming}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.shoujiHaoma}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.xueyuan.shenfenzhengHaoma}
                  </TableCell>
                  <TableCell align="center">

                    {item.jigou === buxuanJigou &&
                      <Tooltip title={item.qitaJigou} placement="top">
                        <Button size='small'>{item.jigou}</Button>
                      </Tooltip>
                    }
                    {item.jigou === buxuanJigou || item.jigou}

                  </TableCell>
                  <TableCell align="center">
                    {getLocalDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="left">
                    {getKexuanInfo(item.kexuanLiebiao)}
                  </TableCell>
                  <TableCell align="center">{item.zhifuJine}</TableCell>
                  <TableCell align="center">
                    {getDingdanZhuangtai(item) === '待机构确认' ?
                      <Button variant='outlined' size='small' onClick={() => setShowWhichDialog(item.dingdanId)}>
                        {getDingdanZhuangtai(item)}</Button>

                      : getDingdanZhuangtai(item) === '机构已确认' ?
                        <Tooltip title={item.jigouFukuanXinxi} placement="top">
                          <Button size='small'>机构已确认</Button>
                        </Tooltip>
                        : getDingdanZhuangtai(item)
                    }

                    {showWhichDialog === item.dingdanId &&
                      <CollectTextDialog title='确认机构订单' open={true}
                        setOutValue={(val) => { if (val) querenJigouDingdan(item, val); console.log(val); setShowWhichDialog('') }} />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {item.saimaChengjis.map((chengji) =>
                      <Button size='small' onClick={() => { window.open(chengji.baogaoDizhi) }}>{chengji.bishiFenshu},</Button>
                    )
                    }
                  </TableCell>
                  <TableCell align="center">
                    {item.saimaChengjis.map((chengji) =>
                      chengji.zhengshuId &&
                      <Button size='small' onClick={() => { window.open(`/res/zhengshu/${item.cepingHuodongId}/AICE${item.cepingHuodongId}${chengji.zhengshuId}.jpg?t=${Date.now()}`, '_blank', 'toolbar=no,scrollbars=no,location=no') }}>{bishiFenshuDengji(chengji)},</Button>
                    )
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={zongshu}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
 
}
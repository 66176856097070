import * as React from 'react';

import {Button,TextField,DialogActions,Box,FormHelperText,DialogContent} from '@mui/material';
import {DialogTitle, RadioGroup, Dialog, FormControlLabel, Radio, Grid} from '@mui/material';

import axios from 'axios';
const quanjuBianliang = require('../changliang/quanju');

export default function AddJigouDialog(props) {
  const {open,setShowAddJigouDialog,refreshFunc} = props;  

  const [jigouMingcheng,setJigouMingcheng] = React.useState('');
  const [fenzhiLiebiao,setFenzhiLiebiao] = React.useState('');
  const [pinyin,setPinyin] = React.useState('');
  const [fukuanFangshi,setFukuanFangshi] = React.useState('自助付款');

  const [tishi,setTishi] = React.useState({
    jigouMingchengFlag: false,
    jigouMingchengTishi:"机构名称至少2个字符",
    pinyinFlag:false,
    pinyinTishi:"必填,请检查填写格式",
    serverErrorFlag: false,
    serverErrorTishi: "服务器端错误！",
  });

  const checkShuru = () => {
    var jigouMingchengFlag = jigouMingcheng.length<2,
    pinyinFlag=!/^[A-Z|!]$/.test(pinyin);

    setTishi({...tishi,
      jigouMingchengFlag,
      pinyinFlag,       
    });

    if(!jigouMingchengFlag && !pinyinFlag)
      return true;
    else
      return false;
  }
  async function post(url, body, options) {
    let result;
      try {
        result = await axios.post(url, body, options);
        result = result.data;        
      } catch (err) {
        result = { status: -99, message: err.message };
      }
    return result;
  }
  const querenTianjia = async () =>{
    if(!checkShuru()) return; 
    let result = await post(quanjuBianliang.houtaiLujing + '/api/guanliyuan/addJigou',
      { jigouMingcheng,fenzhiLiebiao,pinyin,fukuanFangshi },
      { withCredentials: true });
    if(result.status === 1){
      setShowAddJigouDialog(false);
      setJigouMingcheng('');
      setFenzhiLiebiao('');
      setPinyin('');
      refreshFunc();
      setTishi({
        ...tishi,
        serverErrorFlag:false,
      });
    }else{
      setTishi({
        ...tishi,
        serverErrorFlag:true,
        serverErrorTishi:result.message,
      });
    }    
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{props.title}</DialogTitle> 
      <DialogContent style={{ overflow: "hidden" }}>     
      <Grid sx={{mx:3,mt:0, display: 'flex', flexDirection: 'column' }}>
        <Grid item sx={{ display: 'flex', flexDirection: 'row', my: 1 }} >
          <Grid item xs>
            <TextField sx={{width:'100%'}} size="small" 
                value={jigouMingcheng}  onChange={(e)=>{setJigouMingcheng(e.target.value)}} label="机构名称" variant="outlined" 
                error={tishi.jigouMingchengFlag}
                helperText = {tishi.jigouMingchengFlag&&tishi.jigouMingchengTishi}
                />
          </Grid>
          <Grid item xs={4}>
            <TextField sx={{ml:1,}} size="small"
              value={pinyin}  onChange={(e)=>{setPinyin(e.target.value)}} label="排序字母(A-Z或!)" variant="outlined" 
              error={tishi.pinyinFlag}
              helperText = {tishi.pinyinFlag&&tishi.pinyinTishi}
              />
          </Grid>    
        </Grid>

        <Grid item>
          <RadioGroup row
            aria-label="gender"
            
            value={fukuanFangshi}
            onChange={(e)=>setFukuanFangshi(e.target.value)}
            name="radio-buttons-group"
          >
            <FormControlLabel value="自助付款" control={<Radio />} label="自助付款" />
            <FormControlLabel value="机构付款" control={<Radio />} label="机构付款" />          
          </RadioGroup>
        </Grid>
        <Grid item>
          <TextField id="outlined-basic" sx={{my:1,width:'100%',minWidth:'350px'}} size="small" multiline rows={2}
            value={fenzhiLiebiao}  onChange={(e)=>{setFenzhiLiebiao(e.target.value)}} label="分支机构(##分割多个)" variant="outlined" 
            />
        </Grid> 
        <FormHelperText error={tishi.serverErrorFlag}>               
                {tishi.serverErrorFlag && tishi.serverErrorTishi}
        </FormHelperText>
        <Box sx={{ ml: 0, mt:1, fontWeight: 'medium', fontSize:8}}>
        *排序字母表示机构名称第一个汉字的拼音首字母大写; 如果需要将机构名称置顶,请将首字母设为感叹号!</Box>
        <Box sx={{ ml: 0, mt:1, fontWeight: 'medium', fontSize:8}}>
        *多个分支机构可以这样填写：分支机构A##分支机构B##分支机构C 表示有三个分支机构</Box>
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button variant='outlined' onClick={querenTianjia}>确认</Button>
      <Button variant='outlined' onClick={()=>setShowAddJigouDialog(false)}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}



import * as React from 'react';

import { Button, TextField, DialogActions, Box } from '@mui/material';
// import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import {Dialog, Grid,FormControlLabel} from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import { styled } from '@mui/material/styles';

const quanjuBianliang = require('../changliang/quanju');

const Input = styled('input')({
  display: 'none',
});

export default function EditCepingDialog(props) {
  const { open, setOpen, itemData, setItemData, refreshFunc } = props;
  // const [data, setData] = React.useState(itemData);
  const [imgSrc, setImgSrc] = React.useState();
  const [imgFile, setImgFile] = React.useState();  

  function changeField(fieldName, fieldValue) {
    setItemData({
      ...itemData, [fieldName]: fieldValue,
    });
  }

  function handleQueren() {
    //提交更新请求到后台
    gengxinItemData();

    refreshFunc();
    setOpen(false);
    setImgSrc(null);
  }

  function handleQuxiao() {
    setOpen(false);
    setImgSrc(null);
  }

  const gengxinItemData = () => {
    let formData = new FormData();
    formData.append("cepingId",itemData.cepingId);    
    formData.append("cepingMingcheng",itemData.cepingMingcheng);
    formData.append("cepingJianjie",itemData.cepingJianjie);
    formData.append("baomingJiezhiRiqi",itemData.baomingJiezhiRiqi||'');
    formData.append("cepingKaishiRiqi",itemData.cepingKaishiRiqi||'');
    formData.append("showChenji", itemData.showChenji||false);
    formData.append("cepingHaibao", imgFile);
    axios.post(quanjuBianliang.houtaiLujing + "/api/guanliyuan/cepingDataUpload", 
      formData, {
        withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      }      
    }).then((response)=>{
      console.log(response.data);
    },(err)=>{console.log(err);});
  }



return (
  <Dialog open={open} fullWidth={true} maxWidth='md'>
    <DialogTitle>修改测评信息</DialogTitle>
    <Box sx={{ mx: 4, mt: 0, display:'flex', flexDirection:'row'}}>
      <Grid sx={{display:'flex',flexDirection:'column'}}>
        <img id='cepingHaibao' alt='cepingHaibao' height={200} src={imgSrc ? imgSrc : `/res/ceping/${!itemData ? '' : itemData.cepingId}.jpg?t=${Date.now()}`} />
        <label htmlFor="contained-button-file">
          <Input id="contained-button-file" accept=".jpg" name="cepingHaibao" type="file"
            onChange={evt => {
              const [file] = evt.target.files;
              if (file) {
                setImgFile(file);
                setImgSrc(URL.createObjectURL(file));
              }
            }} />
          <Button component="span">
            更改海报
          </Button>
        </label>
      </Grid>
      <Grid xs sx={{ml:4,mr:2}}>
      <Typography>测评ID：{!itemData ? '' : itemData.cepingId}</Typography>
      <Typography>测评名称：{!itemData ? '' : itemData.cepingMingcheng}</Typography>
      <TextField
        id="date"
        label="报名截止日期"
        type="date"        
        value={!itemData ? '' :itemData.baomingJiezhiRiqi}
        onChange={(e) => { changeField('baomingJiezhiRiqi', e.target.value) }}
        sx={{ width: 220,mt:1 }}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="date"
        label="测评开始日期"
        type="date"        
        value={!itemData ? '' :itemData.cepingKaishiRiqi}
        onChange={(e) => { changeField('cepingKaishiRiqi', e.target.value) }}
        sx={{ width: 220,mt:1,mx:1 }}
        size="small"
        InputLabelProps={{ shrink: true, }}
      />
      <FormControlLabel
          control={
            <Checkbox
              checked={!itemData ? false: itemData.showChenji}
              onChange={(event) => changeField('showChenji', event.target.checked)}              
            />
          }
          label="允许显示成绩"
      />
      <TextField sx={{ my: 2, width: '100%', minWidth: '350px' }} multiline rows={4}
        value={!itemData||!itemData.cepingJianjie? '' : itemData.cepingJianjie} 
        onChange={(e) => { changeField('cepingJianjie', e.target.value) }} label="测评简介" variant="outlined" />
      </Grid>
    </Box>
    <DialogActions>
      <Button variant='outlined' onClick={handleQueren}>确认修改</Button>
      <Button variant='outlined' onClick={handleQuxiao}>取消</Button>
    </DialogActions>
  </Dialog>
);
}



import React, { useState, useEffect } from 'react';
import {Button} from '@mui/material'
import axios from 'axios';

const quanjuBianliang = require('../changliang/quanju');

export default function Countdown(props) {
  const [seconds, setSeconds] = useState(0);
  const [counting,setCounting] = useState(false);  
  const [buttonTag,setButtonTag] = useState('获取验证码');

  useEffect(() => {    
    if(!counting) return;
    if (seconds > 0) {      
      setTimeout(() => {
        if(seconds > 1)
          setButtonTag((seconds)+'秒后获取');
        setSeconds(seconds - 1);        
        // if(seconds > 1)
        //   setButtonTag((seconds - 1)+'秒后获取');
        // else
        //   setButtonTag('获取验证码');
      }, 1000);
    } else {
      setButtonTag('获取验证码');
      setSeconds(0);
      setCounting(false);      
    }
  },[counting,seconds]);

  const sendYzm=()=>{
    setCounting(true);
    setSeconds(20);
    setButtonTag('20秒后获取');
    axios.post(quanjuBianliang.houtaiLujing+"/api/yonghu/getYanzhengma",{shoujiHaoma:props.shoujiHaoma},{withCredentials: true})
    .then((response)=>{
      console.log(response.data.message);
    },(err)=>{
      console.log(err);
    });

  }

  return (    
      <Button variant="contained" onClick={sendYzm} disabled={counting||!props.canSend}>
        {buttonTag}
      </Button>    
  );
}
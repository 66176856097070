// 文件路径 ./aice-admin/backend/changliang/quanju.js
const changliang = {};
	changliang.houtaiLujing = 'https://adminaice.aice.org.cn';
	// changliang.houtaiLujing = 'http://192.168.30.15:3088';

changliang.hanziPatt = /^[\u4e00-\u9fa5]{2,}$/;
changliang.shoujiHaomaPatt = /^1[3456789]\d{9}$/; // /^(13[0-9]|14[5|7]|15[0-9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
changliang.youxiangPatt = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
module.exports=changliang;


import {useState,useContext} from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import {Link as ReactLink } from "react-router-dom"
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import axios from 'axios';
import { FormHelperText } from '@mui/material';
import {useNavigate} from "react-router-dom"

import { MyContext } from './context';
import Copyright from './copyright';
const quanjuBianliang = require('./changliang/quanju');
const theme = createTheme();

export default function SignInSide() {  
  const myContext = useContext(MyContext);
  const {setDengluFlag,setJiaose,setGuanliYonghuId,setChaojiFlag,setXingming, 
    suoshuJigou,setSuoshuJigou, chaojiTag, setChaojiTag} = myContext;

  const navigate = useNavigate();
  
  const shoujiHaomaPatt = quanjuBianliang.shoujiHaomaPatt;
  const [tishi,setTishi] = useState({

    shoujiHaomaFlag:false,
    shoujiHaomaTishi:"手机号码有误",

    mimaFlag:false,
    mimaTishi:"密码最少6位",
    
  });

  const [jieguoTishi,setJieguoTishi] = useState({
    dengluJieguoFlag:false,
    dengluJieguoTishi:"用户名或密码错误！",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log(data);

    var shoujiHaomaFlag=!shoujiHaomaPatt.test(data.get('shoujiHaoma')),
    mimaFlag=data.get('mima').length<6;
    setTishi({...tishi,      
      shoujiHaomaFlag:shoujiHaomaFlag,
      mimaFlag:mimaFlag,         
    });

    if(!shoujiHaomaFlag && !mimaFlag ){
        axios.post(quanjuBianliang.houtaiLujing+"/api/guanliyuan/guanliyuanDenglu",
          { shoujiHaoma:data.get('shoujiHaoma'),
            mima:data.get('mima'),
          },
          {withCredentials: true})
        .then((response)=>{
          if(response.data.status===1){
            console.log('登录成功！');            
            setJieguoTishi({
              ...jieguoTishi,
              dengluJieguoFlag:false,
            });
            setChaojiFlag(response.data.chaojiFlag);
            setChaojiTag(response.data.chaojiFlag);   // 打补丁，用于超级管理员标识
            setDengluFlag(true);
            setGuanliYonghuId(data.get('shoujiHaoma'));
            
            if(response.data.suoshuJigou === '系统管理'){
              setJiaose('guanliyuan');     
              setSuoshuJigou('系统管理');         
            }else{
              setJiaose('jigou');
              setSuoshuJigou(response.data.suoshuJigou);
            }
            setXingming(response.data.xingming);
            
          }else{
            console.log('登录失败');
            setJieguoTishi({
              ...jieguoTishi,
              dengluJieguoFlag:true,
            });            
          }
          console.log(response.data);
        },(err)=>{
          console.log(err);
        });
      }

  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/dengluZuoce.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            mt:6,
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LoginIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                AICE后台管理-用户登录
            </Typography>
            <Box component="form" id='dengluForm' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="shoujiHaoma"
                  label="手机号码"
                  name="shoujiHaoma"
                  autoComplete="shoujiHaoma"
                  type="number"
                  error={tishi.shoujiHaomaFlag}
                  helperText = {tishi.shoujiHaomaFlag?tishi.shoujiHaomaTishi:""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="mima"
                  label="登录密码"
                  type="password"
                  id="mima"
                  autoComplete="new-password"
                  error={tishi.mimaFlag}
                  helperText = {tishi.mimaFlag?tishi.mimaTishi:""}
                />
              </Grid>
              <FormHelperText error={jieguoTishi.dengluJieguoFlag}>               
                {jieguoTishi.dengluJieguoFlag?jieguoTishi.dengluJieguoTishi:""}
              </FormHelperText>
              <Grid item xs={12}>

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="记住密码"
              />
               
              </Grid>
              <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                id = 'BtSubmit'
              >
                登 录
              </Button>
              </Grid>
              
              </Grid>
              <Grid container>
                <Grid item xs>
                  <Link component={ReactLink} to='/backend/mimaChongzhi'  variant="body2">
                    忘记密码?
                  </Link>
                </Grid>
               
              </Grid>
            
              <Copyright sx={{ mt: 5 }} />              
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';
import { Button, TextField,Input , DialogActions, Box, } from '@mui/material';
import { Grid, DialogTitle, Dialog, Typography,Alert} from '@mui/material';

import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { MyContext } from "../context";

const quanjuBianliang = require('../changliang/quanju');

export default function EditExcelDialog(props) {
  
  const myContext = React.useContext(MyContext);
  const { guanliYonghuId, setDengluFlag, buxuanJigou, xingming } = myContext;
  const navigate = useNavigate();

  // activeExcelFileName的格式为：202201_20210103003030_13211112222_yishangchuan.xlsx
  // /^\d{6}_\d{14}_\d+_(yishangchuan|yifukuan)\.xlsx$/.test('202201_20210103003030_13211112222_yishangchuan.xlsx')
  const {setShowEditExcelDialog, changeJiaoyanFlag, activeExcelFileName, refreshFunc, handleOpenSnack } = props;

  const jigouExcelDataUrl = '/api/jigou/getJigouExcelData';
  const kexuanKemuDengjiUrl = '/api/jigou/getKexuanKemuDengji';
  const updateExcelUrl = '/api/jigou/updateExcel';
  const jiaoyanTijiaoExcelUrl = '/api/jigou/jiaoyanTijiaoExcel';

  const [excelData,setExcelData] = useState([]);
  //对应Excel数据表，每个字段的校验标记
  const [excelDataFlag,setExcelDataFlag] = useState([]);
  //整个excel表格是否验证通过，通过调用checkFlagArray进行状态展示

  const [serverCheckFlag, setServerCheckFlag] = useState(true);
  const [serverCheckInfo,setServerCheckInfo] = useState('');
  const excelNameInfo = activeExcelFileName.split('_');  // Excel 文件名中包含的信息
  

  // 不参与前端显示的数据，无需使用useState；使用同时会带来一个更新方法中多个setState时，无法及时传递依赖的问题
  let kexuanKemuDengji = useRef([]);
  // const [kexuanKemuDengji,setKexuanKemuDengji] = useState([]);

  const excelTitleList = ['姓名','身份证号码','手机号码','辅导老师','辅导老师手机','报名项目','级别','零售价'];
  const excelTitleWidth = ['5%','15%','10%','6%','10%','10%','5%','5%'];

  function checkFlagArray(flagArray){
    let result = true;
    for(var rowIndex in flagArray){
      // console.log(flagArray[rowIndex]);
      for (var colIndex in flagArray[rowIndex]){
          if(!flagArray[rowIndex][colIndex]) result = false;
        }
    }
    return result;
  }

  // 每次验证一行，返回一行数据的验证Flag数组
  const yanzhengRowData = (rowData) => {
    //最后两个字段（科目和可选等级）比较特殊，需要联动进行判断
    let kemuFlag = false, dengjiFlag = false;
    let tmpKemuDengji = kexuanKemuDengji.current.find( kemuDengji => kemuDengji.kemuMingcheng === rowData[5]);
    if (tmpKemuDengji) { 
      kemuFlag = true;
      //验证等级信息
      //首先必须是1位数字
      if( /^\d{1}$/.test(rowData[6]) && rowData[6] <= tmpKemuDengji.dengjiShuliang)
        dengjiFlag = true;
    };

     return [
      quanjuBianliang.hanziPatt.test(rowData[0]),//姓名验证
      shenfenzhengYanzheng(rowData[1]), //身份证验证
      quanjuBianliang.shoujiHaomaPatt.test(rowData[2]), //手机号码
      quanjuBianliang.hanziPatt.test(rowData[3]), //辅导老师姓名
      quanjuBianliang.shoujiHaomaPatt.test(rowData[4]), //辅导老师手机
      kemuFlag,dengjiFlag,true,
    ];
  }
 
  const shenfenzhengYanzheng = (id) => {
   
    // 1 "验证通过!", 0 //校验不通过
    var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
    //号码规则校验
    if(!format.test(id)){
        return false;
    }
    //区位码校验
    //出生年月日校验   前正则限制起始年份为1900;
    var year = id.substr(6,4),//身份证年
        month = id.substr(10,2),//身份证月
        date = id.substr(12,2),//身份证日
        time = Date.parse(month+'-'+date+'-'+year),//身份证日期时间戳date
        now_time = Date.parse(new Date()),//当前时间戳
        dates = (new Date(year,month,0)).getDate();//身份证当月天数
    if(time>now_time||date>dates){
        return false;
    }
    //校验码判断
    var c = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];   //系数
    var b = ['1','0','X','9','8','7','6','5','4','3','2'];  //校验码对照表
    var id_array = id.split("");
    var sum = 0;
    for(var k=0;k<17;k++){
        sum+=parseInt(id_array[k])*parseInt(c[k]);
    }
    if(id_array[17].toUpperCase() !== b[sum%11].toUpperCase()){
        return false;
    }
    return true;
  }

  //克隆一个同样大小的二维数组，用于保存每个字段的校验标记初始化为校验不通过
  function cloneArrayFlag(excelData){
    return Array.from(excelData, row => new Array(row.length).fill(false));
  }

  useEffect(()=>{
    // console.log('editExcel useEffect !');
    const fetchData = async () => {
      try {
        
        //从服务器获取可选科目等级，用于验证
        // 202201_20210103003030_13211112222_yishangchuan.xlsx 
        let cepingId = activeExcelFileName.split('_')[0];
        let kexuanKemuDengjiResponse = await axios.post(quanjuBianliang.houtaiLujing + kexuanKemuDengjiUrl,
          {cepingId}, { withCredentials: true });
        if(kexuanKemuDengjiResponse.data.status === -1){setDengluFlag(false);navigate("/backend/denglu");};
        
        let kexuanKemuDengjiData = kexuanKemuDengjiResponse.data.shuju;
        // console.log('-- 可选科目等级数据打印：',kexuanKemuDengjiData);
        kexuanKemuDengji.current = kexuanKemuDengjiData;
        // setKexuanKemuDengji(kexuanKemuDengjiData);

        //访问后台获取ExcelData
        let excelDataResponse =  await axios.post(quanjuBianliang.houtaiLujing + jigouExcelDataUrl,
          {activeExcelFileName}, { withCredentials: true });
        let excelFirstSheetData = excelDataResponse.data.shuju[0];
        let noTitleData = excelFirstSheetData.data.filter((item,index) => index>0)

        // 判断是否存在价格列，如果不存在，则根据科目的对应价格添加
        noTitleData = noTitleData.map(item => {
            if(!item[7]){ //如果价格列不存在
              //先查找科目对应的价格
              
              let tmpKemuDengji = kexuanKemuDengjiData.find(km => km.kemuMingcheng === item[5]);
              if(tmpKemuDengji)
                item[7] = tmpKemuDengji.kemuJiage;
              else
                item[7] = 0;
            }
            return item;
          });
        
        // noTitleData = noTitleData.map(item=>((item[7]=item[7]? 0: kexuanKemuDengjiData.find(km => km.kemuMingcheng === item[5]).kemuJiage || 0), item ));

          // 如果某一行的数据列数量不足，则补齐，确保前端可以编辑
          // .map((row,index)=>{
          //   if(row.length<excelTitleList.length) 
          //     row = row.concat(new Array(excelTitleList.length-row.length).fill(''));
          //   return row});
        // console.log('不含标题的Excel数据：',noTitleData);
        //设置ExcelData
        setExcelData(noTitleData);

        //为每一个单元格的数据进行验证
        setExcelDataFlag(Array.from(noTitleData, row => yanzhengRowData(row)));

      } catch (err) { console.log(err); }
    }
    fetchData();

  },[]);

  //修改单个Excel单元格的内容
  function changeCell(rowIndex,colIndex,value){
    let tmpExcelData = [...excelData];
    tmpExcelData[rowIndex][colIndex] = value;
    

    //修改excel数据校验数组,并更新验证状态
    let tmpExcelDataFlag = [...excelDataFlag];
    let tmpRowDataFlag = yanzhengRowData(tmpExcelData[rowIndex]);
    tmpExcelDataFlag[rowIndex] = tmpRowDataFlag;
    console.log('修改后的Excel校验数据',tmpExcelDataFlag);
    setExcelDataFlag(tmpExcelDataFlag);

    //如果修改科目名称列，并且校验成功，则自动修改对应的价格
    if(colIndex === excelTitleList.length-3 ){
      if(tmpRowDataFlag[colIndex])
        tmpExcelData[rowIndex][excelTitleList.length-1] = kexuanKemuDengji.current.find(item => item.kemuMingcheng === value).kemuJiage;
      else
        tmpExcelData[rowIndex][excelTitleList.length-1] = 0;
    }

    console.log('修改后的Excel数据',tmpExcelData);
    setExcelData(tmpExcelData);
  }

  //保存修改按钮动作
  function handleQueren() {
    //提交更新请求到后台
    gengxinExcel();

    refreshFunc();
    setShowEditExcelDialog(false);
    // setOpen(false);
    // setImgSrc(null);
  }
  
  //提交名单动作
  function handleTijiao() {
    //先检查前端所有字段是否都校验通过
    //如果没有通过，则修改标记，提示用户存在错误
    //前端校验自动进行，如果校验不通过，提交按钮为灰色不可提交

    //前端校验通过，请求后端，等待返回结果
    axios.post(quanjuBianliang.houtaiLujing + jiaoyanTijiaoExcelUrl, 
      {activeExcelFileName, excelData}, { withCredentials: true, })
    .then((response)=>{
      console.log(response.data);
      
      // 后端校验通过，刷新父窗口，并关闭窗口，提示用户提交成功
      if(response.data.status === 1){

        refreshFunc();
        setShowEditExcelDialog(false);
        handleOpenSnack();
      }else{// 后端校验失败，提示用户存在错误，并返回
        let shuju = response.data.shuju;
        setServerCheckFlag(false);        
        setServerCheckInfo( (shuju.zishenChongfuFlag?("身份证"+shuju.zishenChongfuShenfenZhengHaoma+"有重复"):"") + 
          (shuju.dbChongfuFlag?("身份证"+shuju.dbChongfuShenfenzhengHaoma+"已报名过对应的科目等级！" ):"") );
      }
    },(err)=>{console.log(err);})

  }


  function handleQuxiao() {
    setShowEditExcelDialog(false);
    // setOpen(false);
    // setImgSrc(null);
  }

  const gengxinExcel = () => {
    // let formData = new FormData();
    // formData.append("activeExcelFileName",activeExcelFileName);
    // formData.append("kemuJieshao",itemData.kemuJieshao);
    // formData.append("kemuMingcheng",itemData.kemuMingcheng);
    // formData.append("kemuTubiao", imgFile);
    axios.post(quanjuBianliang.houtaiLujing + updateExcelUrl, 
      {activeExcelFileName, excelData}, { withCredentials: true, })
    .then((response)=>{
      console.log(response.data);
    },(err)=>{console.log(err);});
  }

  // 从文件名称中，获取状态,文件名格式 /^\d{6}_\d{14}_\d+_(yishangchuan|yishangchuan|yifukuan|yiqueren)\.xlsx$/
  const getExcelZhuangtai = (tmpExcelFileName) => {
    let zhuangtai = tmpExcelFileName.split('_')[5].slice(0,-5);
    let zhuangtaiStr = '';
    switch(zhuangtai){
      case 'yishangchuan': zhuangtaiStr = '已上传，未提交'; break;
      case 'yitijiao': zhuangtaiStr = '已提交，待确认'; break;
      case 'yiqueren': zhuangtaiStr = '已确认,订单已接受'; break;
      default: zhuangtaiStr = '状态错误';
    }
    return zhuangtaiStr;
  }  
  
  // 计算和稷金额（零售价）
  function getHejiJine(){
    if(!excelData) return 0;
    let sum = excelData.reduce( (accumulator, object)=>accumulator+object[7],    0);
    return sum;
  }
  
  //  显示当前可选科目等级，只在“已上传” 状态时才显示。
  const kemuDengjiInfoElement = ()=>{
      if(excelNameInfo[5].split('.')[0] !== 'yishangchuan') {return '';}
      return (
        <Typography variant="h8" sx={{ml:6}}  >
            当前可选报名科目：{ kexuanKemuDengji.current && kexuanKemuDengji.current.map((kmdj) =>
            (<> {kmdj.kemuMingcheng}({'1-'+kmdj.dengjiShuliang}级), </> ) )} 
        </Typography>
      );
  }; 

  

return (
  <Dialog open={true} fullWidth={true} maxWidth={'xl'}>
    <DialogTitle variant="h6" >编辑/查看报名信息</DialogTitle>
    <Typography variant="h8" sx={{ml:6}}>
        批量文件编号ID：{ excelNameInfo[0] + '_' +  excelNameInfo[1] + '_' + excelNameInfo[2]} 
        , 订单数量：{excelData.length + ' 条 '} ，  合计金额（零售价）:{getHejiJine()} 元。
    </Typography>
    <Typography variant="h8" sx={{ml:6}}  color='error.main'>  当前状态： {getExcelZhuangtai(activeExcelFileName) }   </Typography>
     { kemuDengjiInfoElement() }
    <DialogActions sx={{ mx: 2}}>
       
      {serverCheckInfo.length>1 && <Alert severity={serverCheckFlag?"success":"error"}>{serverCheckInfo}</Alert>}

      {checkFlagArray(excelDataFlag)?        
        <Alert severity="success">数据格式验证通过!</Alert>
        :
        <Alert severity="error">格式验证不通过，请检查并修改标记为红色下划线的数据或重新上传!</Alert>
      }

      <Button variant='outlined' onClick={handleQueren} disabled={!activeExcelFileName.includes('yishangchuan')} >保存修改</Button>
      <Button variant='outlined' onClick={handleTijiao} disabled={!checkFlagArray(excelDataFlag) || !activeExcelFileName.includes('yishangchuan')}>提交名单</Button>
      <Button variant='outlined' onClick={handleQuxiao}>取消</Button>
      
    </DialogActions>
    <Box sx={{ mx: 2, mt: 0, mb:2}}>
    <TableContainer component={Paper}>
          <Table size='small' sx={{ minWidth: 700, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {excelTitleList.map((item,rowIndex)=>
                  <TableCell key={rowIndex} width={excelTitleWidth[rowIndex]} align="center">{item}</TableCell>
                )}            
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
              {excelData && excelData.map((rowItem, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  {rowItem && rowItem.filter((columnItem,colIndex)=>colIndex<excelTitleList.length).map( (ziduan,colIndex) =>
                    <TableCell key={colIndex} align="center" sx={{padding:'0px 0px'}}>
                      <Input id="component-disabled" inputProps={{min: 0, style: { textAlign: 'center' }}} 
                        error = {excelDataFlag[rowIndex] ? !excelDataFlag[rowIndex][colIndex] : true}
                        value={ziduan} onChange={(e)=>changeCell(rowIndex,colIndex,e.target.value)}
                        disabled = {colIndex === excelTitleList.length-1 || !activeExcelFileName.includes('yishangchuan')}
                        /> 
                    </TableCell>
                  )}      

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </Box>

  </Dialog>
);
}



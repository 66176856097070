import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function QuerenDeleteExcelDialog(props) {
	const {open, setOpen, refreshFunc, action, } = props;

  const handleQuxiao = () => {
    setOpen(false);
  };

	const handleQueren = () => {
		action();
		refreshFunc();
    setOpen(false);
  };

  return (

      <Dialog
        open={open}
        onClose={handleQuxiao}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"确认从服务器端删除Excel文件?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Excel文件删除后，服务端将删除该文件，请确保已保存或备份删除文件中的重要信息。 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQueren}>确认删除</Button>
          <Button onClick={handleQuxiao} autoFocus>
            取消
          </Button>
        </DialogActions>
      </Dialog>

  );
}
import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Grid,ListSubheader } from '@mui/material';
import axios from 'axios';

const quanjuBianliang = require('../changliang/quanju');

//状态放到父组件中进行管理
export default function XuanJigou({jigou, setJigou,fenzhiJigou, setFenzhiJigou}) {
    const [jigouList,setJigouList] = React.useState([]);
    const [fenzhiJigouList,setFenzhiJigouList] = React.useState([]);    
    //构造一个带有拼音分类的机构列表说明，解决map只能返回一个节点，嵌套后Select无法选择的问题
    const [pinyinFenleiList,setPinyinFenleiList] = React.useState([]);

    React.useEffect(() => {
        axios.post(quanjuBianliang.houtaiLujing + "/api/common/getJigouList",
        {},{ withCredentials: true })
        .then((response) => {
          if(response.data.status === 1) {
            console.log('机构信息获取成功');
            var shuju = response.data.shuju;
            let tmpFenleiList = [];
            let pinyinList= Array.from(new Set(shuju.map((x) => x.pinyin)));
            pinyinList.forEach((pinyin)=>{
                tmpFenleiList.push(['header',pinyin]);
                shuju.filter((x)=>x.pinyin===pinyin).forEach((jigou)=>tmpFenleiList.push(['data',jigou.jigouMingcheng]));
            });

            setPinyinFenleiList(tmpFenleiList);
            setJigouList(shuju);            
          }
        }, (err) => {
          console.log(err);
        });
        
    },[]);

    const handleChangeJigou = (event) => {     
        setJigou(event.target.value);
        var jigouValue = event.target.value;
        let jigouItem = jigouList.find((x)=>x.jigouMingcheng == jigouValue);
        let itemFenzhiList = jigouItem.fenzhiLiebiao?jigouItem.fenzhiLiebiao.split('##'):[];
        itemFenzhiList.pop();//弹出末尾的元素
        setFenzhiJigouList(itemFenzhiList);
        setFenzhiJigou('');
    };

    const handleChangeFenzhiJigou = (event) => {
        setFenzhiJigou(event.target.value);
    };

    return (
        <Grid sx={{display: 'flex', flexDirection: 'row',justifyContent:'center'}}>
            <Grid item sx={{ minWidth: 150}} xs={6}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">请选择报名中心</InputLabel>
                    <Select                              
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"                        
                        value={jigou}
                        label="请选择报名中心"
                        onChange={handleChangeJigou}                    >

                        {pinyinFenleiList.map((xItem,index)=>
                                xItem[0]==='header'?
                                <ListSubheader>{ xItem[1]}</ListSubheader>
                                : 
                                <MenuItem key={index} value={xItem[1]}>{xItem[1]}</MenuItem>                                
                                
                        )}

                    </Select>
                </FormControl>
            </Grid>

            <Grid item sx={{ minWidth: 150, ml:1 }} xs={6}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">如果有分支机构,请选择</InputLabel>
                    <Select                        
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fenzhiJigou}
                        label="如果有分支机构,请选择"
                        onChange={handleChangeFenzhiJigou}
                    >
                        {fenzhiJigouList && fenzhiJigouList.map((item,index) =>
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            )}
                    </Select>
                </FormControl>
            </Grid>

        </Grid>
    );
}

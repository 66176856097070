import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, Divider, Grid, TablePagination, Tooltip, TableSortLabel, } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Snackbar, Alert} from '@mui/material';
import { Link as MaterialLink } from "@mui/material";
import { Link as ReactLink, useNavigate } from "react-router-dom"
import axios from 'axios';

import EditExcelDialog from '../zujian/editExcelDialog';
import UploadExcelDialog from '../zujian/uploadExcelDialog';
import QuerenDeleteExcelDialog from '../zujian/querenDeleteExcelDialog';

import { MyContext } from "../context";
import Copyright from '../copyright';
const quanjuBianliang = require('../changliang/quanju');

export default function PiliangBaoming() {

  const dataTitle = '批量报名文件列表';

  const myContext = React.useContext(MyContext);
  const { guanliYonghuId, setDengluFlag, buxuanJigou, xingming,suoshuJigou } = myContext;
  const navigate = useNavigate();


  const [shujuList, setShujuList] = useState([]);
  const [showUploadExcelDialog, setShowUploadExcelDialog] = useState(false);
  const [showEditExcelDialog, setShowEditExcelDialog] = useState(false);
  const [showQuerenDeleteExcelDialog,setShowQuerenDeleteExcelDialog] = useState(false);
  const [activeExcelFileName, setActiveExcelFileName] = useState('');

  //获取可上传测评的列表，只有未到报名截止期的测评，才允许上传名单
  const [keUploadCepingList,setKeUploadCepingList] = useState([]);

  //保存科目信息，主要使用价格属性
  const [kemuList,setKemuList] = useState([]);

  //触发页面数据刷新的状态
  const [shuaxinCount, setShuaxinCount] = useState(0);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleClickSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  //筛选条件，测评
  

  //报名Excel列表获取、Excel内容获取、Excel文件上传
  const jigouExcelListUrl = '/api/jigou/getJigouExcelList';  
  const keUploadCepingUrl = '/api/jigou/getKeUploadCepingList';
  const deleteExcelUrl = '/api/jigou/deleteExcel'

  useEffect(() => {
    // console.log('use Effect Called!!');
    const fetchData = async () => {
        try {
          // 获取可上传测评活动列表
          let keUploadCepingResponse = await axios.post(quanjuBianliang.houtaiLujing + keUploadCepingUrl,
            {}, { withCredentials: true });
          //检查登录状态，如果未登录，跳转到登录页面
          if(keUploadCepingResponse.data.status === -1){setDengluFlag(false);navigate("/backend/denglu");};
          setKeUploadCepingList(keUploadCepingResponse.data.shuju);

          let excelInfo =  await axios.post(quanjuBianliang.houtaiLujing + jigouExcelListUrl,
           {}, { withCredentials: true });
          //新增一个jiaoyanFlag,用于管理前端校验结果，校验通过后才能提交
          setShujuList(excelInfo.data.shuju.map(item=>({...item,jiaoyanFlag:false,jiaoyanInfo:'校验通过'}) ));
          // console.log(excelInfo);

        } catch (err) { console.log(err); }
    }
    fetchData();

  }, [shuaxinCount]);

  //日期字符串格式转换：YYYYmmDDHHMMss -> YYYY.mm.DD HH:MM:ss
  function formatTime(timeStr){
    //每间隔两个字符插入如下
    let insertList = ['','.','.',' ',':',':',''];
    let segList  = timeStr.match(/.{2}/g);
    let combineStr = segList.map((item,index)=>[item,insertList[index]]).flat().join('');
    return combineStr;
  }


  const deleteExcelAction = async ()=>{
    let deleteExcelResponse = await axios.post(quanjuBianliang.houtaiLujing + deleteExcelUrl,
      {activeExcelFileName}, { withCredentials: true });
    console.log(deleteExcelResponse.data);
  }

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `报名模板.xlsx`;
    link.href = "/res/_baomingMuban.xlsx";
    link.click();
  };

  // 从文件名称中，获取状态,文件名格式 /^\d{6}_\d{14}_\d+_(yishangchuan|yishangchuan|yifukuan|yiqueren)\.xlsx$/
  const getExcelZhuangtai = (tmpExcelFileName) => {
    let zhuangtai = tmpExcelFileName.split('_')[5].slice(0,-5);
    let zhuangtaiStr = '';
    switch(zhuangtai){
      case 'yishangchuan': zhuangtaiStr = <Typography color='#ef6c00'>已上传，未提交</Typography>; break; ; break;
      case 'yitijiao': zhuangtaiStr = '已提交，待确认'; break;
      case 'yiqueren': zhuangtaiStr = <Typography color='#43a047'>已确认,订单已接受</Typography>; break; 
      default: zhuangtaiStr = '状态错误';
    }
    return zhuangtaiStr;
  }

  const getRenshu = (tmpExcelFileName) => tmpExcelFileName.split('_')[3];
  const getJine = (tmpExcelFileName) => parseInt(tmpExcelFileName.split('_')[4]) > 1 ?  tmpExcelFileName.split('_')[4] : '0';

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 4 }}>
        <Typography
          variant="h6"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>
        <Button variant='outlined' sx={{ mb: 2 }} onClick={() => setShowUploadExcelDialog(true)}>
          上传学员报名文件</Button>
        <Button sx={{ mb: 2,ml:2 }} onClick={onDownload} color="primary">
          下载报名模板文件
        </Button>
          <UploadExcelDialog title='上传学员报名文件' open={showUploadExcelDialog}
            setOpen={setShowUploadExcelDialog} keUploadCepingList={keUploadCepingList}
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
          />
        <TableContainer component={Paper}>
          <Table size='small' sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">测评Id</TableCell>
                <TableCell align="center">测评名称</TableCell>
                <TableCell align="center">Excel报名文件</TableCell>
                <TableCell align="center">订单数量</TableCell>
                <TableCell align="center">零售价金额合计</TableCell>
                <TableCell align="center">上传时间</TableCell>
                <TableCell align="center">文件状态</TableCell>
                <TableCell align="center">操作</TableCell>              
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  <TableCell align="center">
                    {item.cepingId}
                  </TableCell>
                  <TableCell align="center">
                    {item.cepingMingcheng}
                  </TableCell>
                  <TableCell align="center">
                    
                      <Button onClick={()=>{setActiveExcelFileName(item.excelFileName);setShowEditExcelDialog(true);}}>
                        {'编辑/查看'}{item.excelFileName.split('_')[1]}
                      </Button> 
                      
                  </TableCell>
                  <TableCell align="center" name='报名人数'>
                      {getRenshu(item.excelFileName)}
                  </TableCell>
                  <TableCell align="center" name='零售价金额合计'>
                      {getJine(item.excelFileName)}
                  </TableCell>
                  <TableCell align="center">
                    {formatTime(item.excelFileName.split('_')[1])}
                  </TableCell>
                  <TableCell align="center">
                    {getExcelZhuangtai(item.excelFileName)}                
                  </TableCell>
                  <TableCell align="center">
                    {item.excelFileName.includes('yishangchuan') &&
                      <Button onClick={()=>{setActiveExcelFileName(item.excelFileName);setShowQuerenDeleteExcelDialog(true);}}>
                        删除文件</Button>
                    }
                    {item.excelFileName.includes('yishangchuan')&& item.jiaoyanFlag &&
                      <Button>提交文件</Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showEditExcelDialog &&
          <EditExcelDialog title='编辑/查看学员报名文件'
            activeExcelFileName={activeExcelFileName} 
            setShowEditExcelDialog={setShowEditExcelDialog}
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
            handleOpenSnack = {handleClickSnackBar}
          />
        }
        {showQuerenDeleteExcelDialog &&
          <QuerenDeleteExcelDialog open={showQuerenDeleteExcelDialog} setOpen={setShowQuerenDeleteExcelDialog}
            action = {deleteExcelAction}
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
          />
        }

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </Alert>
      </Snackbar>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}



import * as React from 'react';

import { Button, TextField, DialogActions, Box, FormHelperText, DialogContent } from '@mui/material';
import {DialogTitle,Typography,Grid,FormControlLabel,Checkbox }from '@mui/material';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import { MyContext } from "../context";

const quanjuBianliang = require('../changliang/quanju');

export default function GuanLianKemuDialog(props) {
  const { open, setShowGuanlianKemuDialog, cepingId } = props;

  const myContext = React.useContext(MyContext);
  const { setDengluFlag, } = myContext;
  const navigate = useNavigate();

  const [suoyouKemu, setSuoyouKemu] = React.useState([]);
  const [kemuMokuai, setKemuMokuai] = React.useState([]);

  const [tishi, setTishi] = React.useState({
    jigouMingchengFlag: false,
    jigouMingchengTishi: "机构名称至少2个字符",
    pinyinFlag: false,
    pinyinTishi: "必须填写【一个大写字母】代表机构名称字母排序",
    serverErrorFlag: false,
    serverErrorTishi: "服务器端错误！",
  });

  React.useEffect(() => {
    //获取所有科目列表，获取已购买科目列表，已关联科目列表
    const fetchData = async () => {
      try {
        let result = await axios.post(quanjuBianliang.houtaiLujing + '/api/guanliyuan/getCepingGuanlianKemu',
          { cepingId }, { withCredentials: true });

        if (result.data.status === -1) {
          // console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else{          
          setSuoyouKemu(result.data.shuju);
          setKemuMokuai(Array.from(new Set(result.data.shuju.map((x) => x.suoshuMokuai))));
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

  }, [cepingId]);

  const setKemuChecked = (kemuId,checked)=>{
    let tmpSuoyoukemu = [...suoyouKemu];
    let idx = tmpSuoyoukemu.findIndex((x)=>x.kemuId==kemuId);
    tmpSuoyoukemu[idx] = {...tmpSuoyoukemu[idx],guanlianFlag:checked};
    setSuoyouKemu(tmpSuoyoukemu);
  }

  const querenXiugai = async () => {
    let result = await axios.post(quanjuBianliang.houtaiLujing + '/api/guanliyuan/xiugaiCepingGuanlianKemu',
      { cepingId, suoyouKemu },
      { withCredentials: true });
    if(result.data.status === 1){
      setShowGuanlianKemuDialog(false);
    }else if (result.data.status === -1) {      
      setDengluFlag(false);
      navigate("/backend/denglu");
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent >
        <Box sx={{ mx: 4, mt: 0 }}>
        {kemuMokuai.map((mokuai, index) => {
									return (
										<React.Fragment key={mokuai}>
											<Grid item xs={12}>
												<Typography variant="h6" sx={{ color: 'text.secondary', m: 0 }}>
													人工智能{mokuai}模块
												</Typography>
											</Grid>

											{suoyouKemu.map((kemu, index) => 
													kemu.suoshuMokuai === mokuai &&
                          <Grid sx={{display:'flex', flexDirection:'row'}}>
                            <Grid item xs={1} key='1'></Grid>
                            <Grid key={kemu.kemuMingcheng} item xs={9} >
                              <FormControlLabel disabled={!kemu.keGenggaiFlag}
                                key={kemu.kemuMingcheng} label={kemu.kemuMingcheng}
                                control={<Checkbox checked={kemu.guanlianFlag}	
                                  onChange={(e) => setKemuChecked(kemu.kemuId,e.target.checked)}/>}													  
                              />									
                            </Grid>
                          </Grid>
												)}

										</React.Fragment>
									)
								})
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={querenXiugai}>确认修改</Button>
        <Button variant='outlined' onClick={() => setShowGuanlianKemuDialog(false)}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}



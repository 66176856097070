import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, Divider, Grid, TablePagination, Tooltip, TableSortLabel, } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
import { Link as MaterialLink } from "@mui/material";
import { Link as ReactLink, useNavigate } from "react-router-dom"
import axios from 'axios';

import ShowExcelDialog from '../zujian/showExcelDialog';
import CollectConfirmDialog from '../zujian/collectConfirmDialog';

import { MyContext } from "../context";
import Copyright from '../copyright';
const quanjuBianliang = require('../changliang/quanju');

export default function PiliangDingdan() {

  const dataTitle = '批量报名文件列表';

  const myContext = React.useContext(MyContext);
  const { guanliYonghuId, setDengluFlag, buxuanJigou, xingming,suoshuJigou } = myContext;
  const navigate = useNavigate();


  const [shujuList, setShujuList] = useState([]);

  const [showConfirmDialog,setShowConfirmDialog] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState();

  const [showExcelDialogFlag, setShowExcelDialogFlag] = useState(false);

  const [activeExcelFileName, setActiveExcelFileName] = useState('');

  //获取可上传测评的列表，只有未到报名截止期的测评，才允许上传名单
  const [keUploadCepingList,setKeUploadCepingList] = useState([]);

  //触发页面数据刷新的状态
  const [shuaxinCount, setShuaxinCount] = useState(0);

  //报名Excel列表获取、Excel内容获取、Excel文件上传
  const allExcelListUrl = '/api/guanliyuan/getAllExcelList';  
  const keUploadCepingUrl = '/api/jigou/getKeUploadCepingList';

  const acceptExcelUrl = '/api/guanliyuan/acceptExcel';
  const rejectExcelUrl = '/api/guanliyuan/rejectExcel';
  

  //确认批量订单已付款的回调方法
  function acceptExcel(){
    //获取当前活动excel文件名，并提交到后台，将对应数据导入数据库中，并修改文件名后缀为：_yiqueren
    //xingming,beizhu,activeExcelFileName
    const pushRequest = async () =>{
      try{
        let acceptExcelResponse = await axios.post(quanjuBianliang.houtaiLujing + acceptExcelUrl,
          {xingming,activeExcelFileName,beizhu:confirmInfo}, { withCredentials: true });
        //检查登录状态，如果未登录，跳转到登录页面
        if(acceptExcelResponse.data.status === -1){setDengluFlag(false);navigate("/backend/denglu");};

      }catch (err) { console.log(err); }
    }
    pushRequest();
  } 

  //驳回批量订单的回调方法
  function rejectExcel(){
    //调用后台，将文件名，修改为未提交状态
    const pushRequest = async () =>{
      try{
        let rejectExcelResponse = await axios.post(quanjuBianliang.houtaiLujing + rejectExcelUrl,
          {activeExcelFileName,beizhu:confirmInfo}, { withCredentials: true });
        //检查登录状态，如果未登录，跳转到登录页面
        if(rejectExcelResponse.data.status === -1){setDengluFlag(false);navigate("/backend/denglu");};
        
      }catch (err) { console.log(err); }
    }
    pushRequest();
  }  

  useEffect(() => {
    // console.log('use Effect Called!!');
    const fetchData = async () => {
        try {
          // 获取可上传测评活动列表
          let keUploadCepingResponse = await axios.post(quanjuBianliang.houtaiLujing + keUploadCepingUrl,
            {}, { withCredentials: true });
          //检查登录状态，如果未登录，跳转到登录页面
          if(keUploadCepingResponse.data.status === -1){setDengluFlag(false);navigate("/backend/denglu");};
          setKeUploadCepingList(keUploadCepingResponse.data.shuju);

          let excelInfo =  await axios.post(quanjuBianliang.houtaiLujing + allExcelListUrl,
           {}, { withCredentials: true });
          //新增一个jiaoyanFlag,用于管理前端校验结果，校验通过后才能提交
          setShujuList(excelInfo.data.shuju.map(item=>({...item,jiaoyanFlag:false,jiaoyanInfo:'校验通过'}) ));
          // console.log(excelInfo);
        } catch (err) { console.log(err); }
    }
    fetchData();

  }, [shuaxinCount]);

  //日期字符串格式转换：YYYYmmDDHHMMss -> YYYY.mm.DD HH:MM:ss
  function formatTime(timeStr){
    //每间隔两个字符插入如下
    let insertList = ['','.','.',' ',':',':',''];
    let segList  = timeStr.match(/.{2}/g);
    let combineStr = segList.map((item,index)=>[item,insertList[index]]).flat().join('');
    return combineStr;
  }

    //  '202201_20210103003030_13211112222_人数_金额_yishangchuan.xlsx'    
  // 从文件名称中，获取状态,文件名格式 /^\d{6}_\d{14}_\d+_(yishangchuan|yishangchuan|yifukuan)\.xlsx$/
  const getExcelZhuangtai = (tmpExcelFileName) => {
    let zhuangtai = tmpExcelFileName.split('_')[5].slice(0,-5);
    let zhuangtaiStr = '';
    switch(zhuangtai){
      case 'yishangchuan': zhuangtaiStr = '已上传，未提交'; break;
      case 'yitijiao': zhuangtaiStr = <Typography color='#ef6c00'>已提交，待确认</Typography>; break;
      case 'yiqueren': zhuangtaiStr = '已确认'; break;
      default: zhuangtaiStr = '状态错误';
    }
    return zhuangtaiStr;
  }



  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 4 }}>
        <Typography
          variant="h6"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>

          <CollectConfirmDialog open={showConfirmDialog} setOpen={setShowConfirmDialog}
            confirmInfo = {confirmInfo} setConfirmInfo={setConfirmInfo}
            accept = {acceptExcel} reject = {rejectExcel}
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
          />

        <TableContainer component={Paper}>
          <Table size='small' sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">测评Id</TableCell>
                <TableCell align="center">测评名称</TableCell>
                <TableCell align="center">Excel报名文件</TableCell>
                <TableCell align="center">报名人数</TableCell>
                <TableCell align="center">合计金额（零售价）</TableCell>
                <TableCell align="center">上传时间</TableCell>
                <TableCell align="center">上传人</TableCell>
                <TableCell align="center">所属机构</TableCell>
                <TableCell align="center">文件状态</TableCell>
                <TableCell align="center">操作</TableCell>              
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  <TableCell align="center">
                    {item.cepingId}
                  </TableCell>
                  <TableCell align="center">
                    {item.cepingMingcheng}
                  </TableCell>
                  <TableCell align="center">                    
                      <Button onClick={()=>{setActiveExcelFileName(item.excelFileName);setShowExcelDialogFlag(true);}}>
                        {'查看 '}{item.excelFileName.split('_')[1]}
                      </Button> 
                                       
                  </TableCell>
                  <TableCell align="center" name='报名人数'>
                    {item.excelFileName.split('_')[3]}
                  </TableCell>
                  <TableCell align="center" name='零售价金额'>
                    {item.excelFileName.split('_')[4]}
                  </TableCell>
                  <TableCell align="center">
                    {formatTime(item.excelFileName.split('_')[1])}
                  </TableCell>
                  <TableCell align="center">
                    {item.xingming}
                  </TableCell>  
                  <TableCell align="center">
                    {item.suoshuJigou}
                  </TableCell>
                  <TableCell align="center">
                    {getExcelZhuangtai(item.excelFileName)}                
                  </TableCell>
                  <TableCell align="center">
                    {item.excelFileName.includes('_yiqueren') ||  
                    <Button onClick={ ()=>{
                        setConfirmInfo("");
                        setActiveExcelFileName(item.excelFileName);
                        setShowConfirmDialog(true);                        
                      }}  id = 'Bt_active' >     
                      确认/驳回订单</Button> }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showExcelDialogFlag &&
          <ShowExcelDialog title='查看学员报名文件'
            activeExcelFileName={activeExcelFileName} 
            setShowExcelDialogFlag={setShowExcelDialogFlag}
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
          />
        }

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}



import * as React from 'react';
import {useState,useEffect} from 'react';

import { Button, TextField, DialogActions, Box,DialogTitle } from '@mui/material';
import {Dialog, Grid,FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import { styled } from '@mui/material/styles';

const quanjuBianliang = require('../changliang/quanju');

const Input = styled('input')({
  display: 'none',
});

export default function UploadExcelDialog(props) {
  const { open, setOpen, refreshFunc, keUploadCepingList, } = props;
  
  const baomingExcelUploadUrl = '/api/jigou/baomingExcelUpload';

  const [excelPath, setExcelPath] = useState('');
  const [excelFile, setExcelFile] = useState();

  const [cepingId,setCepingId] = useState('');
  
  const [tishi,setTishi] = useState({
    cepingIdFlag: false,
    cepingIdTishi:"请选择测评活动",
    excelFileFlag: false,
    excelFileTishi: "请选择要上传的文件",
  });

  const checkShuru = () => {
    console.log('校验输入cepingId:',cepingId);
    var cepingIdFlag = !cepingId || cepingId.length<2;
    var excelFileFlag = !excelPath || excelPath.length<2;

    setTishi({...tishi,
      cepingIdFlag,excelFileFlag,
    });

    if(!cepingIdFlag && !excelFileFlag)
      return true;
    else
      return false;
  }

  function handleQueren() {
    if(!checkShuru()) return false; 

    //提交更新请求到后台
    gengxinItemData();
    refreshFunc();
    setOpen(false);
    setExcelPath(null);
  }

  function handleQuxiao() {
    setOpen(false);
    setExcelPath(null);
  }

  const gengxinItemData = () => {
    let formData = new FormData();

    //用来为后台提供一个临时保存的唯一文件名，由于cepingId是动态生成
    formData.append("reqTime",Date.now());
    formData.append("cepingId",cepingId);  

    formData.append("jigouBaomingExcel", excelFile);
    axios.post(quanjuBianliang.houtaiLujing + baomingExcelUploadUrl, 
      formData, {
        withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      }      
    }).then((response)=>{
      console.log(response.data);
    },(err)=>{console.log(err);});
  }


return (
  <Dialog open={open} fullWidth={true} maxWidth='sm'>
    <DialogTitle>为测评活动上传学员名单</DialogTitle>
    <Box sx={{ mx: 4, my: 3}} >
      <Grid container sx={{ display:'flex', flexDirection:'row'}} spacing={2}>
        <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-simple-select-label">测评活动</InputLabel>
              <Select size='small' 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cepingId}
                label="测评活动"
                onChange={(e)=>{setCepingId(e.target.value);
                  let cepingIdFlag = e.target.value.length<2;setTishi({...tishi, cepingIdFlag,});}}
                
              >
                {keUploadCepingList.map(ceping =>
                  <MenuItem key={ceping.cepingId} value={ceping.cepingId}>{ceping.cepingMingcheng}</MenuItem>
                )}                
              </Select>
            </FormControl>

        </Grid>
        <Grid item xs={8} sx={{display:'flex',flexDirection:'row'}} >
          <Grid item xs={9}>
            <TextField label="选择上传文件" value={excelPath} disabled size="small" 
              sx = {{width: '100%',}}
              inputProps={{style: {fontSize: 14}}} InputLabelProps={{shrink: true,}} />
          </Grid>
          <Grid>
            <label htmlFor="contained-button-file">
                <Input id="contained-button-file" 
                  accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                  name="jigouBaomingExcel" type="file"
                  onChange={evt => {
                    const [file] = evt.target.files;
                    if (file) {
                      setExcelFile(file);
                      setExcelPath(file.name);
                      console.log('已选择上传文件',file.name);
                      let excelFileFlag = file.name<1; setTishi({...tishi, excelFileFlag,});
                    }
                  }} />
              
              <Button component="span">
                选择文件
              </Button>
            </label>    
          </Grid>      
        </Grid>
      </Grid>
      <FormHelperText error={tishi.cepingIdFlag}>      
            {tishi.cepingIdFlag && tishi.cepingIdTishi}                  
      </FormHelperText>
      <FormHelperText error={tishi.excelFileFlag}>      
            {tishi.excelFileFlag && tishi.excelFileTishi}                  
      </FormHelperText>
    </Box>
    <DialogActions>
      <Button variant='outlined' onClick={handleQueren}>上传文件</Button>
      <Button variant='outlined' onClick={handleQuxiao}>取消</Button>
    </DialogActions>
  </Dialog>
);
}



import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';
import { Button, TextField,Input , DialogActions, Box, } from '@mui/material';
import { Grid, DialogTitle, Dialog, Typography,Alert} from '@mui/material';

import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { MyContext } from "../context";

const quanjuBianliang = require('../changliang/quanju');

//供管理员使用，仅仅查看，不需要编辑和校验功能，根据editExcelDialog进行精简
export default function ShowExcelDialog(props) {
  
  const myContext = React.useContext(MyContext);
  const { guanliYonghuId, setDengluFlag, buxuanJigou, xingming } = myContext;
  const navigate = useNavigate();

  // activeExcelFileName的格式为：202201_20210103003030_13211112222_yishangchuan.xlsx
  // /^\d{6}_\d{14}_\d+_(yishangchuan|yifukuan)\.xlsx$/.test('202201_20210103003030_13211112222_yishangchuan.xlsx')
  const {setShowExcelDialogFlag, activeExcelFileName, refreshFunc, handleOpenSnack } = props;

  const jigouExcelDataUrl = '/api/jigou/getJigouExcelData';


  const jiaoyanTijiaoExcelUrl = '/api/jigou/jiaoyanTijiaoExcel';

  const [excelData,setExcelData] = useState([]);
  //对应Excel数据表，每个字段的校验标记
  const [excelDataFlag,setExcelDataFlag] = useState([]);
  //整个excel表格是否验证通过，通过调用checkFlagArray进行状态展示

  const [serverCheckFlag, setServerCheckFlag] = useState(true);
  const [serverCheckInfo,setServerCheckInfo] = useState('');
  const excelNameInfo = activeExcelFileName.split('_');  // Excel 文件名中包含的信息
  

  // 不参与前端显示的数据，无需使用useState；使用同时会带来一个更新方法中多个setState时，无法及时传递依赖的问题
  let kexuanKemuDengji = useRef([]);
  // const [kexuanKemuDengji,setKexuanKemuDengji] = useState([]);

  const excelTitleList = ['姓名','身份证号码','手机号码','辅导老师','辅导老师手机','报名项目','级别','零售单价'];
  const excelTitleWidth = ['5%','10%','10%','6%','10%','10%','5%','5%'];

  useEffect(()=>{
    // console.log('editExcel useEffect !');
    const fetchData = async () => {
      try {

        //访问后台获取ExcelData
        let excelDataResponse =  await axios.post(quanjuBianliang.houtaiLujing + jigouExcelDataUrl,
          {activeExcelFileName}, { withCredentials: true });
        let excelFirstSheetData = excelDataResponse.data.shuju[0];
        let noTitleData = excelFirstSheetData.data.filter((item,index) => index>0)
          // 如果某一行的数据列数量不足，则补齐，确保前端可以编辑
          // .map((row,index)=>{
          //   if(row.length<excelTitleList.length) 
          //     row = row.concat(new Array(excelTitleList.length-row.length).fill(''));
          //   return row});
        // console.log('不含标题的Excel数据：',noTitleData);
        //设置ExcelData
        setExcelData(noTitleData);

      } catch (err) { console.log(err); }
    }
    fetchData();

  },[]);


  
  //提交名单动作
  function handleTijiao() {
    //先检查前端所有字段是否都校验通过
    //如果没有通过，则修改标记，提示用户存在错误
    //前端校验自动进行，如果校验不通过，提交按钮为灰色不可提交

    //前端校验通过，请求后端，等待返回结果
    axios.post(quanjuBianliang.houtaiLujing + jiaoyanTijiaoExcelUrl, 
      {activeExcelFileName, excelData}, { withCredentials: true, })
    .then((response)=>{
      console.log(response.data);
      
      // 后端校验通过，刷新父窗口，并关闭窗口，提示用户提交成功
      if(response.data.status === 1){

        refreshFunc();
        setShowExcelDialogFlag(false);
        handleOpenSnack();
      }else{// 后端校验失败，提示用户存在错误，并返回
        let shuju = response.data.shuju;
        setServerCheckFlag(false);        
        setServerCheckInfo( (shuju.zishenChongfuFlag?("身份证"+shuju.zishenChongfuShenfenZhengHaoma+"有重复"):"") + 
          (shuju.dbChongfuFlag?("身份证"+shuju.dbChongfuShenfenzhengHaoma+"已报名过对应的科目等级！" ):"") );
      }
    },(err)=>{console.log(err);})

  }


  function handleQuxiao() {
    setShowExcelDialogFlag(false);
    // setOpen(false);
    // setImgSrc(null);
  }

  //获取当前批量订单的合计总金额
  function getHejiJine(){
    if(!excelData) return 0;
    let sum = excelData.reduce( (accumulator, object)=>accumulator+object[7],    0);
    return sum;
  }

   // 从文件名称中，获取状态,文件名格式 /^\d{6}_\d{14}_\d+_(yishangchuan|yishangchuan|yifukuan|yiqueren)\.xlsx$/
   const getExcelZhuangtai = (tmpExcelFileName) => {
    let zhuangtai = tmpExcelFileName.split('_')[5].slice(0,-5);
    let zhuangtaiStr = '';
    switch(zhuangtai){
      case 'yishangchuan': zhuangtaiStr = '已上传，未提交'; break;
      case 'yitijiao': zhuangtaiStr = '已提交，待确认'; break;
      case 'yiqueren': zhuangtaiStr = '已确认,订单已接受'; break;
      default: zhuangtaiStr = '状态错误';
    }
    return zhuangtaiStr;
  }  
  

return (
  <Dialog open={true} fullWidth={true} maxWidth={'xl'}>
    <DialogTitle  >查看报名信息</DialogTitle> 
    <Typography  variant="h8"   sx={{ml:6}}>
        批量文件编号ID：{ excelNameInfo[0] + '_' +  excelNameInfo[1] + '_' + excelNameInfo[2]} 
    </Typography>
    <Typography variant="h8"  color='error.main'  sx={{ml:6}}>当前状态： {getExcelZhuangtai(activeExcelFileName) } 。 </Typography>
    <DialogActions sx={{ mx: 2}}>
      报名人数：{excelData.length}人 , 合计金额（零售价）: {getHejiJine()} 元 。
       <Button variant='outlined' onClick={handleQuxiao}>关闭</Button>
    </DialogActions>
    <Box sx={{ mx: 2, mt: 0, mb:2}}>
    <TableContainer component={Paper}>
          <Table size='small' sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {excelTitleList.map((item,rowIndex)=>
                  <TableCell key={rowIndex} width={excelTitleWidth[rowIndex]} align="center">{item}</TableCell>
                )}            
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
              {excelData && excelData.map((rowItem, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  {rowItem && rowItem.filter((columnItem,colIndex)=>colIndex<excelTitleList.length).map( (ziduan,colIndex) =>
                    <TableCell key={colIndex} align="center" sx={{padding:'0px 0px'}}>                      
                      {ziduan}  
                    </TableCell>
                  )}      

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </Box>

  </Dialog>
);
}



import React, { useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Countdown from './fasongYanzhengma';
import BasicModal from './chenggongTishi';

import { FormHelperText, Link} from '@mui/material';
import axios from 'axios';
import {useNavigate, Link as ReactLink} from "react-router-dom"

import Copyright from '../copyright';

const quanjuBianliang = require('../changliang/quanju');
const theme = createTheme();

export default function MimaChongzhi() {


  const navigate = useNavigate();

  const [tishi,setTishi] = useState({

    shoujiHaomaFlag:false,
    shoujiHaomaTishi:"手机号码有误",

    mimaFlag:false,
    mimaTishi:"密码最少6位",

    yanzhengmaFlag:false,
    yanzhengmaTishi:"验证码为6位数字",
    
  });

  const [jieguoTishi,setJieguoTishi] = useState({
    zhuceJieguoFlag:false,
    zhuceJieguoTishi:"验证码错误",
  });

  //后台可用的发送验证码的手机号码
  const [canSend,setCanSend] = useState(false);
  const [fasongHaoma,setFasongHaoma] = useState('');

  const [zhuceChenggongFlag,setZhuceChenggongFlag] = useState(false);

  const shoujiHaomaPatt = quanjuBianliang.shoujiHaomaPatt;

  const handleSubmit = (event) => {
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    
    
    var shoujiHaomaFlag=!shoujiHaomaPatt.test(data.get('shoujiHaoma')),    
    mimaFlag=data.get('mima').length<6,
    yanzhengmaFlag=data.get('yanzhengma').length!==6;
       

    setTishi({...tishi,      
      shoujiHaomaFlag:shoujiHaomaFlag,      
      mimaFlag:mimaFlag,
      yanzhengmaFlag:yanzhengmaFlag,      
    });  
    

    if(!shoujiHaomaFlag && !mimaFlag && !yanzhengmaFlag){
        axios.post(quanjuBianliang.houtaiLujing+"/api/guanliyuan/mimaChongzhi",
          { yanzhengma:data.get('yanzhengma'),shoujiHaoma:data.get('shoujiHaoma'),
            mima:data.get('mima'),
          },
          {withCredentials: true})
        .then((response)=>{
          if(response.data.status===0){
            console.log('重置失败！');            
            setJieguoTishi({...jieguoTishi, zhuceJieguoFlag:true,zhuceJieguoTishi:response.data.message});  
            setZhuceChenggongFlag(false);               
          }else{
            console.log('重置成功');
            setJieguoTishi({...tishi, zhuceJieguoFlag:false});
            setZhuceChenggongFlag(true); 
            setTimeout(()=>{
              setZhuceChenggongFlag(false);
              navigate("/backend/denglu");
            },2000);        
          }
          console.log(response.data);
        },(err)=>{
          console.log(err);
        });
      }

    // console.log(tishi);
    //eslint-disable-next-line no-console
    
  };

  const checkShoujiHaoma=(event)=>{
    //console.log(event.currentTarget.value);
    const flag = shoujiHaomaPatt.test(event.currentTarget.value);
    setTishi({...tishi,
      shoujiHaomaFlag:!flag,
    });
    if(flag){
      setCanSend(true);
      setFasongHaoma(event.currentTarget.value);
    }else{
      setCanSend(false);
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AppRegistrationIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            重新设置密码
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2} >
              
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="shoujiHaoma"
                  label="手机号码"
                  name="shoujiHaoma"
                  autoComplete="shoujiHaoma"
                  type="number"
                  error={tishi.shoujiHaomaFlag}
                  helperText = {tishi.shoujiHaomaFlag?tishi.shoujiHaomaTishi:""}
                  onChange = {checkShoujiHaoma}
                />
              </Grid>
              
              <Grid item xs={12} sm={7.5} >
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="yanzhengma"
                  label="手机验证码"
                  name="yanzhengma"
                  autoComplete="email" 
                  type = "number"  
                  error = {tishi.yanzhengmaFlag}
                  helperText = {tishi.yanzhengmaFlag?tishi.yanzhengmaTishi:""}               
                />
              </Grid>
              
              <Grid item xs={12} sm={4.5} >
              <Box
                component="div"
                m={1} //margin
                >
                <Countdown canSend={canSend} shoujiHaoma={fasongHaoma}/>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="mima"
                  label="新登录密码"
                  type="password"
                  id="mima"
                  autoComplete="new-password"
                  error={tishi.mimaFlag}
                  helperText = {tishi.mimaFlag?tishi.mimaTishi:""}
                />
              </Grid>

              <FormHelperText error={jieguoTishi.zhuceJieguoFlag}>               
                {jieguoTishi.zhuceJieguoFlag?jieguoTishi.zhuceJieguoTishi:""}
              </FormHelperText>

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              重置密码
            </Button>
            <Grid container>
                <Grid item xs>

                  
                  <Link component={ReactLink} to='/backend/denglu' variant="body2">
                    {"我要登录"}
                  </Link>
                </Grid>
              </Grid>
          </Box>
        </Box>
        <BasicModal open={zhuceChenggongFlag} caozuoming='密码重置' />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
import * as React from 'react';

import { Button, TextField, DialogActions, Box, } from '@mui/material';
import { Grid, DialogTitle, Dialog, Typography,} from '@mui/material';

import axios from 'axios';

import { styled } from '@mui/material/styles';

const quanjuBianliang = require('../changliang/quanju');

const Input = styled('input')({
  display: 'none',
});

export default function EditKemuDialog(props) {
  const { open, setOpen, itemData, setItemData, refreshFunc } = props;
  // const [data, setData] = React.useState(itemData);
  const [imgSrc, setImgSrc] = React.useState();
  const [imgFile, setImgFile] = React.useState();

  // 修改字段总的信息
  function changeField(fieldName, fieldValue) {
    setItemData({
      ...itemData, [fieldName]: fieldValue,
    });
  }

  function handleQueren() {
    //提交更新请求到后台
    gengxinKemu();

    refreshFunc();
    setOpen(false);
    setImgSrc(null);
  }

  function handleQuxiao() {
    setOpen(false);
    setImgSrc(null);
  }

  const gengxinKemu = () => {
    let formData = new FormData();
    formData.append("kemuJieshao",itemData.kemuJieshao);
    formData.append("kemuMingcheng",itemData.kemuMingcheng);
    formData.append("kemuTubiao", imgFile);
    axios.post(quanjuBianliang.houtaiLujing + "/api/guanliyuan/kemuDataUpload", 
      formData, {
        withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      }      
    }).then((response)=>{
      console.log(response.data);
    },(err)=>{console.log(err);});
  }


return (
  <Dialog open={open}>
    <DialogTitle>修改科目信息</DialogTitle>
    <Box sx={{ ml: 4, mt: 0, display:'flex', flexDirection:'row' }}>
      <Grid sx={{display:'flex',flexDirection:'column'}}>
        <img id='kemuTubiao' alt='kemuTubiao' height={100} src={imgSrc ? imgSrc : `/res/svg/${!itemData ? '' : itemData.kemuMingcheng}32.svg?t=${Date.now()}`} />
        <label htmlFor="contained-button-file">
          <Input id="contained-button-file" accept=".svg" name="kemuTubiao" type="file"
            onChange={evt => {
              const [file] = evt.target.files;
              if (file) {
                setImgFile(file);
                setImgSrc(URL.createObjectURL(file));
              }
            }} />
          <Button component="span">
            更改图标
          </Button>
        </label>
      </Grid>
      <Grid xs sx={{ml:2,mr:2}}>
        <Typography>科目名称：{!itemData ? '' : itemData.kemuMingcheng}</Typography>
        <TextField sx={{ my: 2, width: '100%', minWidth: '300px' }} multiline rows={3}
          value={!itemData||!itemData.kemuJieshao? '' : itemData.kemuJieshao} onChange={(e) => { changeField('kemuJieshao', e.target.value) }} label="科目介绍" variant="outlined" />          
      </Grid>
      
      <Grid xs sx={{ml:1,mr:1}}>
        <Typography>价格：{!itemData ? '' : itemData.jiage}</Typography>
        <TextField sx={{ my: 2, width: '50px', minWidth: '100px' }} multiline rows={1}
          value={!itemData||!itemData.jiage? '' : itemData.jiage} onChange={(p) => { changeField('jiage', p.target.value) }} label="修改价格￥" variant="outlined" />          
      </Grid>
    </Box>

    <DialogActions>
      <Button variant='outlined' onClick={handleQueren}>确认修改</Button>
      <Button variant='outlined' onClick={handleQuxiao}>取消</Button>
    </DialogActions>
  </Dialog>
);
}



import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, Divider, Grid, TablePagination } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Link as MaterialLink } from "@mui/material";
import { Link as ReactLink, useNavigate } from "react-router-dom"

import { MyContext } from "../context";

import axios from 'axios';

import Copyright from '../copyright';
import ProgressButton from './progressButton';

const quanjuBianliang = require('../changliang/quanju');

//const cepingList = [{ id: '202102', value: '2021秋季测评' }];
const orderbyList = [{ id: 'kemu', value: '按科目等级' }, { id: 'renshu', value: '按人数' }];
const mokuaiList = ['所有模块', '程序设计', '智能控制', '产品结构', '算法基础', '应用领域'];


export default function KaoshiGuanli() {

    const dataTitle = '考卷管理';
    //后台数据访问接口
    const cepingListUrl = '/api/common/getCepingList';
    const cepingKemuXinxiUrl = '/api/guanliyuan/getCepingKemuXinxi';
    const kaojuanUrl = '/api/guanliyuan/getKaojuan';
    const gengxinKaojuanUrl = '/api/guanliyuan/gengxinKaojuan';
    const tuisongKaoshengUrl = '/api/guanliyuan/tuisongKaosheng';

    const myContext = React.useContext(MyContext);
    const { setDengluFlag, buxuanJigou } = myContext;
    const navigate = useNavigate();

    const [cepingList, setCepingList] = useState([]);
    const [cepingId, setCepingId] = useState();//cepingList[0].id);
    const [suoshuMokuai, setSuoshuMokuai] = useState('所有模块');
    const [shujuList, setShujuList] = useState([]);
    const [kaojuanList, setKaojuanList] = useState([]);
    const [tuisongJishuList,setTuisongJishuList] = useState([]);

    //触发页面数据刷新的状态
    const [shuaxinCount,setShuaxinCount] = useState(0);

    const handleChangeCepingId = (event) => setCepingId(event.target.value);

    function parseISOLocal(s) {
		var b = s.split(/\D/);
		return new Date(b[0], b[1] - 1, b[2], b[3]||'00', b[4]||'00', b[5]||'00');
	}

    //*/
    const getJishuXinxi = (cepingKemuItem,xIndex)=>{
        // {tuisongJishuList.find((x)=>x.kemuDengji===(item.kemuMingcheng+'##0')).tuisongshu}
        // /{tuisongJishuList.find((x)=>x.kemuDengji===(item.kemuMingcheng+'##0')).zongshu}
        
        let tmp = tuisongJishuList?
            tuisongJishuList.find((x)=>x.kemuDengji===(cepingKemuItem.kemu.kemuMingcheng+'##'+xIndex)):null;
        if(tmp)           
            return tmp.tuisongshu + '/' + tmp.zongshu;        
        else
            return '';
    }
    
    const getTuisongshu = (item,xIndex) =>{
        let tmp = tuisongJishuList?
            tuisongJishuList.find((x)=>x.kemuDengji===(item.kemu.kemuMingcheng+'##'+xIndex)):null;
        if(tmp)           
            return parseInt(tmp.tuisongshu);        
        else
            return 0;
    }

    const shifouTuisong = (item,xIndex) =>{
        let cunzaiShijuanFlag = item['kaojuan'+(xIndex+1)] && item['kaojuan'+(xIndex+1)].length>2;
        if(!cunzaiShijuanFlag) return false;

        let tmp = tuisongJishuList?
            tuisongJishuList.find((x)=>x.kemuDengji===(item.kemu.kemuMingcheng+'##'+xIndex)):null;
        if(tmp)           
            return parseInt(tmp.tuisongshu)<parseInt(tmp.zongshu);   
        else
            return false;
    }
    //*/

    const gengxinKaojuan = ()=>{
        axios.post(quanjuBianliang.houtaiLujing + gengxinKaojuanUrl,
            { shujuList,kaojuanList },
            { withCredentials: true })
            .then((response) => {
                //用户未登录
                if (response.data.status === 1) {
                    console.log('更新考卷信息成功！');                    
                } else if (response.data.status === -1) {
                    console.log('用户未登录');
                    setDengluFlag(false);
                    navigate("/backend/denglu");
                } else {
                    console.log('服务器错误:' + response.data.message);
                }
                console.log(response.data);
            }, (err) => {
                console.log(err);
            });
    };

    const tuisongKaosheng = (cepingId,kemuId,kemuMingcheng,dengji,kaojuanId)=>{

        axios.post(quanjuBianliang.houtaiLujing + tuisongKaoshengUrl,
            { cepingId, kemuId, kemuMingcheng,dengji,kaojuanId},
            { withCredentials: true })
            .then((response) => {                
                if (response.data.status === 1) {
                    console.log('更新考卷信息成功！'+response.data.message);  
                    //刷新本页面的数据,
                    setShuaxinCount((pre)=>pre+1);                  
                } else if (response.data.status === -1) {
                    console.log('用户未登录');
                    setDengluFlag(false);
                    navigate("/backend/denglu");
                } else {
                    console.log('服务器错误:' + response.data.message);
                }
                console.log(response.data);
            }, (err) => {
                console.log(err);
            });

    };


    useEffect(() => {
        
        const fetchData = async () => {
			try {//获取所有测评id
                let cepingListResult = await axios.post(quanjuBianliang.houtaiLujing + cepingListUrl, 
                    { withCredentials: true });
                let tmpCepingList = [];
                if (cepingListResult.data.status ===1) tmpCepingList = cepingListResult.data.shuju;
                    else console.log('服务器错误:' + cepingListResult.data.message);
                setCepingList(tmpCepingList);       
            } catch (err) { console.log(err); }
		};
		fetchData();
        //获取最后一个测评ID作为默认，并更新列表数据
        
        /*/
        axios.post(quanjuBianliang.houtaiLujing + cepingListUrl,
            { withCredentials: true })
            .then((response) => {
              if (response.data.status === 1) {
                // console.log('确认测评列表成功！');
                setCepingList(response.data.shuju);
                // console.log('---------');
                // console.log(response.data.shuju);
              } else {
                console.log('服务器错误:' + response.data.message);
              }
            }, (err) => {
              console.log(err);
            });
        //*/

        //获取可选的考卷信息
        /*/
        axios.post(quanjuBianliang.houtaiLujing + kaojuanUrl,
            { cepingId, suoshuMokuai },
            { withCredentials: true })
            .then((response) => {
                //用户未登录
                if (response.data.status === 1) {
                    console.log('获取考卷数据成功！');                    
                    setKaojuanList(response.data.shuju.filter(
                        (kaojuan)=>parseISOLocal(kaojuan.mayEndDate)-Date.now() > 1000*60*60*4
                    ));
                } else if (response.data.status === -1) {
                    console.log('用户未登录');
                    setDengluFlag(false);
                    navigate("/backend/denglu");
                } else {
                    console.log('服务器错误:' + response.data.message);
                }
                console.log(response.data);
            }, (err) => {
                console.log(err);
            });
        //*/

    }, []);

    useEffect(() => { 
        const fetchData = async () => {
			try {
                //获取可选的考卷信息
                let kaojuanXinxiResult = await axios.post(quanjuBianliang.houtaiLujing + kaojuanUrl,
                    { cepingId, suoshuMokuai }, { withCredentials: true });
                if(kaojuanXinxiResult.data.status === -1){setDengluFlag(false);navigate("/backend/denglu");};
                if(kaojuanXinxiResult.data.status === 1)
                    setKaojuanList(kaojuanXinxiResult.data.shuju.filter(
                        (kaojuan)=>parseISOLocal(kaojuan.mayEndDate)-Date.now() > 1000*60*60*4
                    ));
                
                //获取测评科目<->考卷关联信息
                let cepingKemuXinxiResult = await axios.post(quanjuBianliang.houtaiLujing + cepingKemuXinxiUrl,
                    { cepingId, suoshuMokuai }, { withCredentials: true });
                if (cepingKemuXinxiResult.data.status === 1){
                    setShujuList(cepingKemuXinxiResult.data.shuju);
                    setTuisongJishuList(cepingKemuXinxiResult.data.tuisongJishuList);
                }

            } catch (err) { console.log(err); }
		};
		fetchData();

        /*/
        axios.post(quanjuBianliang.houtaiLujing + cepingKemuXinxiUrl,
            { cepingId, suoshuMokuai },
            { withCredentials: true })
            .then((response) => {                
                if (response.data.status === 1) {
                    console.log('获取' + dataTitle + ' 数据成功！');
                    setShujuList(response.data.shuju);
                    setTuisongJishuList(response.data.tuisongJishuList);                    
                } else if (response.data.status === -1) {
                    console.log('用户未登录');
                    setDengluFlag(false);
                    navigate("/backend/denglu");
                } else {
                    console.log('服务器错误:' + response.data.message);
                }
                console.log(response.data);
            }, (err) => {
                console.log(err);
            });
        //*/

    }, [cepingId, suoshuMokuai,shuaxinCount]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    {dataTitle}
                </Typography>
                <Box component="span"
                    sx={{
                        display: 'flex',
                        p: 2, my: 2,
                        border: '1px dashed',
                        borderColor: 'grey.400',
                        borderRadius: 2,
                    }}>
                    <Grid container>
                        <Grid item xs={2} sx={{ mr: 3 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label">测评活动</InputLabel>
                                <Select
                                    label='测评活动'
                                    labelId="cepingId"
                                    id="cepingId"
                                    value={cepingId}
                                    onChange={handleChangeCepingId}
                                >
                                    {cepingList.map((item) => {
                                        return (
                                            <MenuItem key={item.cepingId} value={item.cepingId}>{item.cepingMingcheng}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9.5} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <RadioGroup row
                                aria-label="gender"
                                name="suoshuMokuai"
                                value={suoshuMokuai}
                                onChange={(e) => setSuoshuMokuai(e.target.value)}
                                size="small"
                                sx={{ mr: 3 }}
                            >
                                {mokuaiList.map((item, index) => (
                                    <FormControlLabel key={item} value={item} control={<Radio size='small' />} label={item} />
                                ))}

                            </RadioGroup>
                            <ProgressButton title='保存考卷' myCallback={gengxinKaojuan}/>                            
                        </Grid>
                    </Grid>
                </Box>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">科目名称</TableCell>
                                <TableCell align="center">等级考卷</TableCell>
                                <TableCell align="center">推送考生</TableCell>
                                <TableCell align="center">推送状态</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
                            {shujuList && shujuList.map((item, index) => (
                                <React.Fragment>
                                    <TableRow
                                        key={index}
                                        sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                                    >
                                        <TableCell rowSpan={item.kemu.dengjiShuliang} align="center">
                                            {item.kemu.kemuMingcheng}
                                        </TableCell>
                                        <TableCell align="center">
                                        <FormControl fullWidth size="small" disabled={getTuisongshu(item,0)>0}>
                                                    <InputLabel >等级1考卷</InputLabel>
                                                    <Select                                                        
                                                        label='kaojuan1'                                                        
                                                        labelId='kaojuan1'
                                                        id='kaojuan1'
                                                        defaultValue=''
                                                        value={item.kaojuan1}
                                                        onChange={(e) => {
                                                            
                                                            let tmpItem = { ...item, kaojuan1: e.target.value };
                                                            let newShujuList = [...shujuList];
                                                            newShujuList[index] = tmpItem;
                                                            setShujuList(newShujuList);
                                                            console.log(newShujuList);
                                                        }}
                                                    >
                                                        {kaojuanList.map((kaojuan) => {
                                                            return (
                                                                <MenuItem key={kaojuan.paperId} value={kaojuan.paperId}>
                                                                    {kaojuan.paperName+ kaojuan.mayStartDate+'开始' + kaojuan.mayEndDate+'结束'}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                        <ProgressButton title='推送考生' myDisabled={!shifouTuisong(item,0)}
                                            myCallback=
                                                    {()=>tuisongKaosheng(item.cepingCepingId,item.kemuId,item.kemu.kemuMingcheng,0,item.kaojuan1)}
                                                />                                        
                                        </TableCell>
                                        <TableCell align="center">
                                            {getJishuXinxi(item,0)}
                                        </TableCell>
                                    </TableRow>
                                    {Array(item.kemu.dengjiShuliang - 1).fill(0).map((x, xIndex) => (
                                        <TableRow key={xIndex}>

                                            <TableCell align="center">

                                                <FormControl fullWidth size="small" disabled={getTuisongshu(item,xIndex + 1)>0}>
                                                    <InputLabel >等级{xIndex + 2}考卷</InputLabel>
                                                    <Select
                                                        label={'kaojuan' + (xIndex + 2)}
                                                        labelId={'kaojuan' + (xIndex + 2)}
                                                        id={'kaojuan' + (xIndex + 2)}
                                                        defaultValue=''
                                                        value={item['kaojuan' + (xIndex + 2)]}
                                                        onChange={(e) => {
                                                            let tmpItem = { ...item, ['kaojuan' + (xIndex + 2)]: e.target.value };
                                                            let newShujuList = [...shujuList];
                                                            newShujuList[index] = tmpItem;
                                                            setShujuList(newShujuList);
                                                        }}
                                                    >
                                                        {kaojuanList.map((kaojuan) => 
                                                                <MenuItem key={kaojuan.paperId} value={kaojuan.paperId}>
                                                                    {kaojuan.paperName+ kaojuan.mayStartDate+'开始' + kaojuan.mayEndDate+'结束'}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <ProgressButton title='推送考生' myDisabled={!shifouTuisong(item,xIndex+1)}
                                                    myCallback=
                                                    {()=>tuisongKaosheng(item.cepingCepingId,item.kemuId,item.kemu.kemuMingcheng,(xIndex+1),item['kaojuan'+(xIndex+2)])}
                                                />                                               
                                            </TableCell>
                                            <TableCell align="center">
                                                {getJishuXinxi(item,xIndex+1)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    }
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Copyright sx={{ mt: 5 }} />
            </Container>
        </React.Fragment>
    );
}
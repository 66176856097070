import * as React from 'react';

import {Button,TextField,DialogActions,Box,FormControlLabel,Checkbox,FormHelperText} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

const quanjuBianliang = require('../changliang/quanju');

export default function AddXitongGuanliyuanDialog(props) {
  const {open,addGuanliyuan,setShowXitongDialog,refreshFunc} = props;
  const [xingming,setXingming] = React.useState('');
  const [shoujiHaoma,setShoujiHaoma] = React.useState('');
  const [jigou, setJigou] = React.useState('');
  const [fenzhiJigou, setFenzhiJigou] = React.useState('');

  const [mima,setMima] = React.useState('');
  const [chaojiFlag,setChaojiFlag] = React.useState(false);  

  const [tishi,setTishi] = React.useState({
    xingmingFlag: false,
    xingmingTishi:"姓名请输入至少2个汉字",
    shoujiHaomaFlag:false,
    shoujiHaomaTishi:"手机号码有误",
    mimaFlag:false,
    mimaTishi:"密码最少6位",
    serverErrorFlag: false,
    serverErrorTishi: "服务器端错误！",
  });

  const checkShuru = () => {
    const hanziPatt = quanjuBianliang.hanziPatt;
    const shoujiHaomaPatt = quanjuBianliang.shoujiHaomaPatt;

    var xingmingFlag=!hanziPatt.test(xingming),
    shoujiHaomaFlag=!shoujiHaomaPatt.test(shoujiHaoma),    
    mimaFlag=mima.length<6;

    setTishi({...tishi,
      xingmingFlag:xingmingFlag,
      shoujiHaomaFlag:shoujiHaomaFlag,
      mimaFlag:mimaFlag,  
    });

    if(!xingmingFlag && !shoujiHaomaFlag && !mimaFlag)
      return true;
    else
      return false;
  }

  const querenTianjia = async () =>{
    if(!checkShuru()) return; 
    let result = await addGuanliyuan({xingming,shoujiHaoma,mima,chaojiFlag,suoshuJigou:'系统管理'});
    if(result.status === 1){
      setShowXitongDialog(false);
      setXingming('');
      setShoujiHaoma('');
      setMima('');
      refreshFunc();
      setTishi({
        ...tishi,
        serverErrorFlag:false,
      });
    }else{
      setTishi({
        ...tishi,
        serverErrorFlag:true,
        serverErrorTishi:result.message,
      });
    }
    // setOutValue({xingming,shoujiHaoma,mima,chaojiFlag,suoshuJigou:'系统管理'});
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{props.title}</DialogTitle>
      <Box sx={{mx:4,mt:0}}>        
        <TextField id="outlined-basic" sx={{my:1,width:'100%',minWidth:'350px'}} size="small"
          value={xingming}  onChange={(e)=>{setXingming(e.target.value)}} label="姓名" variant="outlined" 
          error={tishi.xingmingFlag}
          helperText = {tishi.xingmingFlag?tishi.xingmingTishi:""}
          />
        <TextField id="outlined-basic" sx={{my:1,width:'100%',minWidth:'350px'}} size="small"
          value={shoujiHaoma}  onChange={(e)=>{setShoujiHaoma(e.target.value)}} label="手机号码" variant="outlined" 
          error={tishi.shoujiHaomaFlag}
          helperText = {tishi.shoujiHaomaFlag?tishi.shoujiHaomaTishi:""}
          />        
        <TextField id="outlined-basic" sx={{my:1,width:'100%',minWidth:'350px'}} size="small"
          value={mima}  onChange={(e)=>{setMima(e.target.value)}} label="默认密码" variant="outlined" 
          error={tishi.mimaFlag}
          helperText = {tishi.mimaFlag?tishi.mimaTishi:""}
          />
        <FormControlLabel
            control={
              <Checkbox checked={chaojiFlag} onChange={(e)=>{setChaojiFlag(e.target.checked)}} name="jason" />
            }
            label="超级管理员"
          />
        <FormHelperText error={tishi.serverErrorFlag}>               
                {tishi.serverErrorFlag && tishi.serverErrorTishi}
        </FormHelperText>
      </Box>
      <DialogActions>
      <Button variant='outlined' onClick={querenTianjia}>确认</Button>
      <Button variant='outlined' onClick={()=>setShowXitongDialog(false)}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}



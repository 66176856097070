import * as React from 'react';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LogoutIcon from '@mui/icons-material/Logout';
import GradingIcon from '@mui/icons-material/Grading';
import BallotIcon from '@mui/icons-material/Ballot';
import ListItemText from '@mui/material/ListItemText';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import TopicIcon from '@mui/icons-material/Topic';
import SchoolIcon from '@mui/icons-material/School';
import MailIcon from '@mui/icons-material/Mail';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import { useNavigate, Routes, Route, Link as ReactLink, } from "react-router-dom"


import axios from 'axios'
import { MyContext } from './context';

import SuoyouDingdan from './admin/suoyouDingdan';
import SuoyouXueyuan from './admin/suoyouXueyuan';
import KaoshiGuanli from './admin/kaoshiGuanli';
import SuoyouGuanliyuan from './admin/suoyouGuanliyuan';
import SuoyouJigou from './admin/suoyouJigou';
import SuoyouKemu from './admin/suoyouKemu';
import SuoyouCeping from './admin/suoyouCeping';

import JigouDingdan from './jigou/jigouDingdan';
import JigouXueyuan from './jigou/jigouXueyuan';
import PiliangBaoming from './jigou/piliangBaoming';
import PiliangDingdan from './admin/piliangDingdan';

const quanjuBianliang = require('./changliang/quanju');
const drawerWidth = 200;

export default function ZhuDaohang() {
	const myContext = useContext(MyContext);
	const { setDengluFlag, setJiaose, jiaose, setGuanliYonghuId, chaojiFlag, chaojiTag } = myContext;
	const navigate = useNavigate();

	const tuichu = () => {
		setDengluFlag(false);
		navigate('/')
		setGuanliYonghuId('');
		setJiaose('');
		//服务端需要清空session
		axios.post(quanjuBianliang.houtaiLujing + "/api/yonghu/tuichu",
			{},
			{ withCredentials: true })
			.then((response) => {
				if (response.data.status === 1) {
					console.log('退出成功！');
				} else {
					console.log('退出失败');
				}
			}, (err) => {
				console.log(err);
			});
	};

	console.log('左边主导航的超级管理员标记 chaojiFlag = ', chaojiFlag);
	console.log('左边主导航的角色 jiaose = ', jiaose);

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				variant="permanent"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
				}}
			>
				<Toolbar />
				<Box sx={{ overflow: 'auto' }}>
					{jiaose === 'guanliyuan' &&
						<>
							<List>
								<ListItem button component={ReactLink} to='/backend/suoyouDingdan'>
									<ListItemIcon>
										<BallotIcon />
									</ListItemIcon>
									<ListItemText primary='订单管理' />
								</ListItem>
								<ListItem button component={ReactLink} to='/backend/suoyouXueyuan'>
									<ListItemIcon>
										<SupervisorAccountIcon />
									</ListItemIcon>
									<ListItemText primary='学员管理' />
								</ListItem>
								<ListItem button component={ReactLink} to='/backend/kaoshiGuanli'>
									<ListItemIcon>
										<GradingIcon />
									</ListItemIcon>
									<ListItemText primary='考试管理' />
								</ListItem>
								<ListItem button component={ReactLink} to='/backend/piliangDingdan'>
									<ListItemIcon>
										<AutoAwesomeMotionIcon />
									</ListItemIcon>
									<ListItemText primary='批量订单' />
								</ListItem>
							</List>
							<Divider />
							<List>
								<ListItem button component={ReactLink} to='/backend/suoyouKemu'>
									<ListItemIcon>
										<TopicIcon />
									</ListItemIcon>
									<ListItemText primary='科目管理' />
								</ListItem>
								<ListItem button component={ReactLink} to='/backend/suoyouCeping'>
									<ListItemIcon>
										<SchoolIcon />
									</ListItemIcon>
									<ListItemText primary='测评管理' />
								</ListItem>
								<ListItem button component={ReactLink} to='/backend/suoyouJigou'>
									<ListItemIcon>
										<HomeWorkIcon />
									</ListItemIcon>
									<ListItemText primary='机构管理' />
								</ListItem>
							</List>
						</>
					}

					{jiaose === 'jigou' &&
						<List>
							<ListItem button component={ReactLink} to='/backend/jigou/jigouDingdan'>
								<ListItemIcon>
									<BallotIcon />
								</ListItemIcon>
								<ListItemText primary='本机构订单' />
							</ListItem>

							<ListItem button component={ReactLink} to='/backend/jigou/piliangBaoming'>
								<ListItemIcon>
									<FileCopyIcon />
								</ListItemIcon>
								<ListItemText primary='批量报名管理' />
							</ListItem>
						</List>
					}

					<Divider />
					<List>

						{jiaose === 'guanliyuan' && chaojiTag &&
							<ListItem button component={ReactLink} to='/backend/suoyouGuanliyuan'>
								<ListItemIcon>
									<ManageAccountsIcon />
								</ListItemIcon>
								<ListItemText primary='配置管理人员' />
							</ListItem>
						}
						<ListItem button onClick={tuichu}>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText primary='退出登录' />
						</ListItem>
					</List>
				</Box>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, pt: 3, pl:0 }}>
			{jiaose === 'guanliyuan' &&
				<Routes>
					<Route path="/backend/suoyouDingdan" element={<SuoyouDingdan />} />
					<Route path="/backend/suoyouXueyuan" element={<SuoyouXueyuan />} />
					<Route path="/backend/kaoshiGuanli" element={<KaoshiGuanli />} />
					<Route path="/backend/suoyouGuanliyuan" element={<SuoyouGuanliyuan />} />
					<Route path="/backend/suoyouJigou" element={<SuoyouJigou />} />
					<Route path="/backend/suoyouKemu" element={<SuoyouKemu />} />
					<Route path="/backend/suoyouCeping" element={<SuoyouCeping />} />
					<Route path="/backend/piliangDingdan" element={<PiliangDingdan />} />
					<Route path="*" element={<SuoyouDingdan />} />
				</Routes>
			}
			{jiaose === 'jigou' &&
				<Routes>
					<Route path="/backend/jigou/jigouDingdan" element={<JigouDingdan />} />
					<Route path="/backend/jigou/piliangBaoming" element={<PiliangBaoming />} />						

					<Route path="*" element={<JigouDingdan />} />
				</Routes>
			}

			</Box>
		</Box>
	);
}

import * as React from 'react';
import { useEffect, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';
import { CssBaseline, Container, Typography, TablePagination, Button, Grid, } from '@mui/material';
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import { MyContext } from "../context";

import AddJigouDialog from '../zujian/addJigouDialog';
import Copyright from '../copyright';
const quanjuBianliang = require('../changliang/quanju');

export default function SuoyouJigou() {
    const dataTitle = '品牌机构列表';
    //后台数据访问接口
    const backDataUrl = '/api/guanliyuan/suoyouJigou';
    
  const myContext = React.useContext(MyContext);
  const { setDengluFlag, } = myContext;
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);  
  const [shujuList, setShujuList] = useState([]);

  const [showAddJigouDialog, setShowAddJigouDialog] = useState(false);
  //触发页面数据刷新的状态
  const [shuaxinCount, setShuaxinCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //加载我的订单
  useEffect(() => {
    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      {page,rowsPerPage},
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          console.log('获取'+dataTitle+' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page,rowsPerPage,shuaxinCount]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography> 

        <Grid container sx={{justifyContent:'flex-end'}}>
          <Button variant='outlined' sx={{ mb: 2 }} onClick={() =>setShowAddJigouDialog(true)} 
            >添加机构</Button>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650,'td, th': { border: 1,borderColor:'grey.400' }}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">序号</TableCell>
                <TableCell align="center">机构名称</TableCell>
                <TableCell align="center">分支机构</TableCell>           
                
              </TableRow>
            </TableHead>
            <TableBody sx={{border: 1,borderColor:'grey.400'}}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1,borderColor:'grey.400' } }}
                >
                  <TableCell width="10%" align="center" component="th" scope="row">
                    {index+page*rowsPerPage+1}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {item.jigouMingcheng}
                  </TableCell>
                  <TableCell width="50%" align="center">  
                    {item.fenzhiLiebiao.replaceAll('##',',')}                    
                  </TableCell>

                </TableRow>
              ))}
            <AddJigouDialog title='添加机构' open={showAddJigouDialog}
              setShowAddJigouDialog={setShowAddJigouDialog} 
              refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
            />
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={zongshu}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}
import * as React from 'react';
import { useEffect, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, Button} from '@mui/material';
import { CssBaseline, Container, Typography, TablePagination, Grid, } from '@mui/material';

import { useNavigate } from "react-router-dom"
import { MyContext } from "../context";
import axios from 'axios';

import EditKemuDialog from '../zujian/editKemuDialog';
import AddKemuDialog from '../zujian/addKemuDialog';
import Copyright from '../copyright';
const quanjuBianliang = require('../changliang/quanju');

export default function SuoyouKemu() {
    const dataTitle = '测评科目列表';
    //后台数据访问接口
    const backDataUrl = '/api/guanliyuan/suoyouKemu';
    
  const myContext = React.useContext(MyContext);
  const { setDengluFlag, } = myContext;
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);  
  const [shujuList, setShujuList] = useState([]);

  const [refreshCount,setRefreshCount] = useState(0);
  const [showDialog,setShowDialog] = useState(false);
  const [itemData,setItemData] = useState();

  const [showAddKemuDialog,setShowAddKemuDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //加载我的订单
  useEffect(() => {
    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      {page,rowsPerPage},
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          console.log('获取'+dataTitle+' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page,rowsPerPage,refreshCount]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>  

        <Grid container sx={{justifyContent:'flex-end'}}>
          <Button variant='outlined' sx={{ mb: 2 }} onClick={() =>setShowAddKemuDialog(true)} 
            >添加科目</Button>
        </Grid>      

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650,'td, th': { border: 1,borderColor:'grey.400' }}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">序号</TableCell>
                <TableCell align="center">科目名称</TableCell>
                <TableCell align="center">科目图标</TableCell>
                <TableCell align="center">等级</TableCell>
                <TableCell align="center">价格</TableCell>
                <TableCell align="center">所属模块</TableCell>
                <TableCell align="center">科目介绍</TableCell>                
                <TableCell align="center">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{border: 1,borderColor:'grey.400'}}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1,borderColor:'grey.400' } }}
                >
                  <TableCell align="center" component="th" scope="row">
                  {index+page*rowsPerPage+1}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {item.kemuMingcheng}
                  </TableCell>
                  <TableCell align="center">    
                    <img alt={item.id} height="70" src={`/res/svg/${item.kemuMingcheng}32.svg?t=${Date.now()}`} />                    
                  </TableCell>
                  <TableCell align="center">{item.dengjiShuliang}</TableCell>
                  <TableCell align="center">{item.jiage}</TableCell>
                  <TableCell align="center">{item.suoshuMokuai}</TableCell>
                  <TableCell width="30%" align="center">{item.kemuJieshao}</TableCell>                  
                  <TableCell align="center">
                    <Button variant='outlined' onClick={()=>{setItemData(item);setShowDialog(true);}}>修改</Button>                    
                  </TableCell>
                </TableRow>
              ))}
              <EditKemuDialog open={showDialog} setOpen={setShowDialog} itemData={itemData} setItemData={setItemData}
                refreshFunc={()=>setRefreshCount((pre)=>pre+1)} />

              <AddKemuDialog title='添加科目' open={showAddKemuDialog}
                setOpen={setShowAddKemuDialog} 
                refreshFunc={() => setRefreshCount((pre) => pre + 1)}
              />
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={zongshu}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}
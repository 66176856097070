import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, Divider, Grid, TablePagination, Tooltip, TableSortLabel, Radio} from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
// import { Link as MaterialLink } from "@mui/material";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import axios from 'axios';

import CollectTextDialog from "../zujian/collectTextDialog"
import { MyContext } from "../context";
import Copyright from '../copyright';
const quanjuBianliang = require('../changliang/quanju');

export default function JigouDingdan() {

  const dataTitle = '本机构订单';
  //后台数据访问接口
  const backDataUrl = '/api/guanliyuan/jigouDingdan';
  const querenJigouDingdanUrl = '/api/guanliyuan/querenJigouDingdan';

  const myContext = React.useContext(MyContext);
  const { guanliYonghuId, setDengluFlag, buxuanJigou, xingming,suoshuJigou } = myContext;
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);

  const [shujuList, setShujuList] = useState([]);
  const [showWhichDialog, setShowWhichDialog] = useState('');

  //触发页面数据刷新的状态
  const [shuaxinCount, setShuaxinCount] = useState(0);

  //筛选条件，测评
  const cepingListUrl = '/api/common/getCepingList';

  const [cepingId, setCepingId] = useState('');
  
  const [cepingList, setCepingList] = useState([]);
  
  const [orderDirection, setOrderDirection] = useState('desc');

  const [shujuFilterYifukuan, setShujuFilterYifukuan] = useState();  // 将源数据过滤出已付款的数据（或者赋值为不过滤）

  
  useEffect(() => {

    axios.post(quanjuBianliang.houtaiLujing + cepingListUrl,
      { withCredentials: true })
      .then((response) => {
        if (response.data.status === 1) {
          // console.log('确认测评列表成功！');
          setCepingList(response.data.shuju);
          // console.log('---------');
          // console.log(response.data.shuju);
        } else {
          console.log('服务器错误:' + response.data.message);
        }
      }, (err) => {
        console.log(err);
      });

  }, []);

  const getDingdanZhuangtai = (item) => {
    return (item.xuehuiZhifuZhuangtai || item.alipayZhuangtai) ? '已完成' :
      item.jigouFukuanFlag ?
        (item.jigouFukuanXinxi && item.jigouFukuanXinxi.length > 2 ?
          '机构已确认'
          : '待机构确认')
        : '未完成'
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const querenJigouDingdan = (item, beizhu) => {
    console.log('---调用确认机构订单后台----');
    //请求后台，更新订单机构付款信息备注
    axios.post(quanjuBianliang.houtaiLujing + querenJigouDingdanUrl,
      {
        dingdanId: item.dingdanId,
        dingdanBeizhu: xingming + '(' + guanliYonghuId + '),' + getFormattedDate() + ',' + beizhu + ';'
      },
      { withCredentials: true })
      .then((response) => {
        if (response.data.status === 1) {
          console.log('确认机构订单信息成功！');
          setShuaxinCount((pre) => pre + 1);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }

  function getFormattedDate() {
    var date = new Date();
    var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    return str;
  }

  //加载我的订单
  useEffect(() => {

    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      {
        page, rowsPerPage, cepingId,
        yixuanJigou: suoshuJigou,
        orderDirection,
      },
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          // console.log('获取' + dataTitle + ' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        // console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page, rowsPerPage, shuaxinCount, cepingId, orderDirection]);

  const getKexuanInfo = (kexuanLiebiaoStr) => {
    var result = '';
    var kexuanInfo = kexuanLiebiaoStr.split(';');
    kexuanInfo.pop();
    kexuanInfo.forEach((item) => {
      var kemuInfo = item.split('##');
      result += kemuInfo[0] + (parseInt(kemuInfo[1]) + 1) + '级,';
      //gengxinKemuFlag(idx,true,parseInt(kemuInfo[1]));
    });
    return result;
  }

  function appendLeadingZeroes(n){
    if(n <= 9){
      return "0" + n;
    }
    return n
  }

  const getLocalDate= (dateInfo) => {
    let current_datetime = new Date(dateInfo);
    let formatted_date = current_datetime.getFullYear() + "-" + appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" + 
       appendLeadingZeroes(current_datetime.getDate()) + " " + appendLeadingZeroes(current_datetime.getHours()) + ":" +
       appendLeadingZeroes(current_datetime.getMinutes()) + ":" + appendLeadingZeroes(current_datetime.getSeconds());
 
    // console.log(formatted_date);
    return formatted_date;
  }
  
  function bishiFenshuDengji(item){
    if(!item.bishiFenshu || item.bishiFenshu.length<1) return '';
    let fenshu = parseInt(item.bishiFenshu);
    if(fenshu<60) return '再接再厉';
    else if(fenshu<90) return '合格';
    else return '优秀';
  }

  // // 选择只显示已付款订单，或者只显示未付款订单
  // function zhiXianshiYifukuanDingdan(flag){
  //   if(flag === true) { setShujuFilterYifukuan(shujuList.filter(() => (
  //       shujuList.jigouFukuanFlag || shujuList.xuehuiZhifuZhuangtai || shujuList.alipayZhuangtai)))  };
  //   if(flag === false) { setShujuFilterYifukuan(shujuList.filter(() => (
  //       !shujuList.jigouFukuanFlag && !shujuList.xuehuiZhifuZhuangtai && !shujuList.alipayZhuangtai)))  };
  // }

  // console.log('zhiXianshiYifukuanDingdan',zhiXianshiYifukuanDingdan(true)) ;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 4 }}>
        <Typography
          variant="h6"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>
        <Box component="span"
          sx={{
            display: 'flex',
            p: 2, my: 2,
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: 2,
          }}>
          <Grid container>
            <Grid item xs={4} sx={{ mr: 3 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">测评活动</InputLabel>
                <Select
                  label='测评活动'
                  labelId="cepingId"
                  id="cepingId"
                  value={cepingId}
                  onChange={(e) => { setCepingId(e.target.value); setPage(0) }}
                >
                  {cepingList && cepingList.map((item) => {
                    return (
                      <MenuItem key={item.cepingId} value={item.cepingId}>{item.cepingMingcheng}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
       
                  </Grid>
          </Grid>
        </Box>
        <TableContainer component={Paper}>
          <Table size='small' sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">订单号</TableCell>
                <TableCell align="center">订购人</TableCell>
                <TableCell align="center">所选机构</TableCell>
                <TableCell align="center">辅导老师姓名</TableCell>
                <TableCell align="center">辅导老师手机号码</TableCell>
                <TableCell align="center">
                  <TableSortLabel active={true} direction={orderDirection}
                    onClick={() => orderDirection == 'desc' ? setOrderDirection('asc') : setOrderDirection('desc')}>
                    订单时间
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">详情</TableCell>
                <TableCell align="center">零售金额</TableCell>
                <TableCell align="center">订单状态</TableCell>                
                <TableCell align="center">成绩和证书</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
            {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  <TableCell component="th" scope="row">
                    {getDingdanZhuangtai(item) === '机构已确认' || item.dingdanId}
                    {getDingdanZhuangtai(item) === '机构已确认' &&
                      <Tooltip title={item.jigouFukuanXinxi} placement="top">
                        <Button size='small'>{item.dingdanId}</Button>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title={item.shoujiHaoma} placement="top">
                      <Button size='small'>{item.xueyuan.xingming}</Button>
                    </Tooltip>

                  </TableCell>
                  <TableCell align="center">

                    {item.jigou === buxuanJigou &&
                      <Tooltip title={item.qitaJigou} placement="top">
                        <Button size='small'>{item.jigou}</Button>
                      </Tooltip>
                    }
                    {item.jigou === buxuanJigou || item.jigou}

                  </TableCell>
                  <TableCell align="center"  name = '辅导老师姓名'>
                      {item.fudaoLaoshiXingming}
                  </TableCell>
                  <TableCell align="center" name = '辅导老师手机号码'>
                      {item.fudaoLaoshiShoujiHaoma}
                  </TableCell>
                  <TableCell align="center">
                    {getLocalDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="left">
                    {getKexuanInfo(item.kexuanLiebiao)}
                  </TableCell>
                  <TableCell align="center">{item.zhifuJine}</TableCell>
                  <TableCell align="center">
                    {getDingdanZhuangtai(item) === '待机构确认' || getDingdanZhuangtai(item)}
                    {getDingdanZhuangtai(item) === '待机构确认' && (
                      suoshuJigou === '系统管理员' ? (
                      <Button variant='outlined' size='small' onClick={() => setShowWhichDialog(item.dingdanId)}>
                        {getDingdanZhuangtai(item)}</Button> ) : '等候AICE确认付款情况'
                        )
                    }

                    {showWhichDialog === item.dingdanId &&
                      <CollectTextDialog title='确认机构订单' open={true}
                        setOutValue={(val) => { if (val) querenJigouDingdan(item, val); console.log(val); setShowWhichDialog('') }} />
                    }
                  </TableCell>
                  <TableCell align="center">
                  {item.saimaChengjis.map((chengji)=>
                      chengji.zhengshuId &&
                      <Button key={chengji.zhengshuId} size='small'  onClick={()=>{window.open(`/res/zhengshu/${item.cepingHuodongId}/AICE${item.cepingHuodongId}${chengji.zhengshuId}.jpg?t=${Date.now()}`,'_blank','toolbar=no,scrollbars=no,location=no')}}>{bishiFenshuDengji(chengji)},</Button>
                    )
                    }
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={zongshu}
          rowsPerPage= {rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}
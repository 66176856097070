
import { AccountBalance } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Typography, Box, } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import ZhuDaohang from './zhuDaohang';

import { useContext } from "react";
import { MyContext } from './context';
import SignInSide from './signinside';
import Copyright from './copyright';
import MimaChongzhi from './admin/mimaChongzhi';
import { Routes, Route, Navigate} from 'react-router';

function App() {

  const myContext = useContext(MyContext);
  const { dengluFlag, guanliYonghuId, xingming , suoshuJigou } = myContext;

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <AccountBalance sx={{ mr: 1 }} />
          <Typography variant="h5" noWrap component="div">
            AICE-管理后台
          </Typography>
          {dengluFlag &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }} >
              <Typography sx={{ mr: 1 }}>{xingming + ' / ' + suoshuJigou}</Typography>

            </Box>
          }
        </Toolbar>
      </AppBar>

      {dengluFlag &&
        <ZhuDaohang />
      }
      {dengluFlag ||
        <Routes>
          <Route exact path="/" element={<SignInSide/>} >            
          </Route>
          <Route path="/backend/denglu" element={<SignInSide/>} />
          <Route path="/backend/mimaChongzhi" element={<MimaChongzhi/>} />
          <Route path="*" element={<SignInSide/>} />            
        </Routes>
      }
    </>
  );
}

export default App;

import * as React from 'react';

import {Button,TextField,DialogActions,Box} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

export default function CollectTextDialog(props) {
  const {setOutValue} = props;
  const [value,setValue] = React.useState('');
  const [open,setOpen] = React.useState(props.open);

  return (
    <Dialog open={open}>
      <DialogTitle>{props.title}</DialogTitle>
      <Box sx={{mx:4,mt:0}}>
        <Typography>请填写与机构有关的订单确认信息：</Typography>
        <TextField id="outlined-basic" sx={{my:2,width:'100%',minWidth:'350px'}} multiline rows={2}
          value={value}  onChange={(e)=>{setValue(e.target.value)}} label="订单备注" variant="outlined" />
      </Box>
      <DialogActions>
      <Button variant='outlined' onClick={()=>{setOpen(false);setOutValue(value);}}>确认已支付</Button>
      <Button variant='outlined' onClick={()=>{setOpen(false);setValue('');setOutValue(null)}}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}



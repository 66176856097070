import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, Divider, Grid, TablePagination } from '@mui/material';
import { Link as MaterialLink } from "@mui/material";
import { Link as ReactLink, useNavigate } from "react-router-dom"
import axios from 'axios';

import { MyContext } from "../context";
import Copyright from '../copyright';
import AddXitongGuanliyuanDialog from '../zujian/addXitongGuanliyuanDialog';
import AddJigouGuanliyuanDialog from '../zujian/addJigouGuanliyuanDialog';
import { Flag } from '@mui/icons-material';

const quanjuBianliang = require('../changliang/quanju');

export default function SuoyouGuanliyuan() {
  const dataTitle = '系统管理人员列表';
  //后台数据访问接口
  const backDataUrl = '/api/guanliyuan/suoyouGuanliyuan';
  const addGuanliyuanUrl = '/api/guanliyuan/addGuanliyuan';
  // 停用-启用指定的管理员账号
  const tingyongGuanliyuanUrl = '/api/guanliyuan/tingyongGuanliyuan';  // 对指定管理员账号操作“启用-停用”

  const myContext = React.useContext(MyContext);
  const { setDengluFlag, chaojiFlag, guanliYonghuId, jiaose} = myContext;
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);
  const [shujuList, setShujuList] = useState([]);
  const [showXitongDialog, setShowXitongDialog] = useState(false);
  const [showJigouDialog, setShowJigouDialog] = useState(false);
  //触发页面数据刷新的状态
  const [shuaxinCount, setShuaxinCount] = useState(0);

  async function post(url, body, options) {
    let result;
      try {
        result = await axios.post(url, body, options);
        result = result.data;
        console.log('----WWWW', result);
      } catch (err) {
        result = { status: -99, message: err.message };
      }
    return result;
  }

  const addGuanliyuan = async (guanliyuanInfo) => {
    let result = await post(quanjuBianliang.houtaiLujing + addGuanliyuanUrl,
      { ...guanliyuanInfo },
      { withCredentials: true });
    if (result.status === -1) {
      console.log('用户未登录');
      setDengluFlag(false);
      navigate("/backend/denglu");
    }
    return result;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  
  };

  //加载我的订单
  useEffect(() => {
    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      { page, rowsPerPage },
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          console.log('获取' + dataTitle + ' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page, rowsPerPage, shuaxinCount]);


 // 对指定管理员账号操作“启用-停用”
  const updateGuanliyuanStatus = (shoujiHaoma) => {
    const fetchData = async () => {
      try {
        let updateGuanliyuanStatusResponse = await axios.post(quanjuBianliang.houtaiLujing + tingyongGuanliyuanUrl,
          { shoujiHaoma }, { withCredentials: true });
        //检查登录状态，如果未登录，跳转到登录页面
        if (updateGuanliyuanStatusResponse.data.status === -1) { setDengluFlag(false); navigate("/backend/denglu"); };
        //刷新页面
        setShuaxinCount((pre) => pre + 1);
      } catch (err) { console.log(err); }
    }
    fetchData();    
  };


  // 显示账号的状态文字
  const zhanghaoStatus = (_stat) => {    
    let zhuangtaiStr = '';
    switch(_stat){
      case 1: zhuangtaiStr = <Typography color='error' >已暂停使用</Typography>; break;
      case 0: zhuangtaiStr = '正常'; break;    
      default: zhuangtaiStr =  <Typography color='error' >状态错误</Typography>;      
    }    
    return zhuangtaiStr;
  }  

  console.log('身份信息打印： guanliYonghuId =' , guanliYonghuId , '\n jiaose = ', jiaose , '\n chaojiFlag = ', chaojiFlag);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>
        <Button variant='outlined' sx={{ mb: 2 }} onClick={() => setShowXitongDialog(true)}
        ><Typography>添加系统管理员</Typography></Button>
          <AddXitongGuanliyuanDialog title='添加系统管理员' open={showXitongDialog}
            addGuanliyuan={addGuanliyuan} setShowXitongDialog={setShowXitongDialog} 
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
          />
        <Button variant='outlined' sx={{ mb: 2, ml: 2 }} onClick={() => setShowJigouDialog(true)}
        ><Typography>添加机构管理员</Typography></Button>

          <AddJigouGuanliyuanDialog title='添加机构管理员' open={showJigouDialog}
            addGuanliyuan={addGuanliyuan} setShowJigouDialog={setShowJigouDialog} 
            refreshFunc={() => { setShuaxinCount((pre) => pre + 1); }}
          />


        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, 'td, th': { border: 1, borderColor: 'grey.400' } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">姓名</TableCell>
                <TableCell align="center">手机号码</TableCell>
                <TableCell align="center">所属机构</TableCell>
                <TableCell align="center">超级管理</TableCell>
                <TableCell align="center">电子邮箱</TableCell>
                <TableCell align="center">账号状态</TableCell>
                <TableCell align="center">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 1, borderColor: 'grey.400' }}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1, borderColor: 'grey.400' } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {item.xingming}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {item.shoujiHaoma}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {item.suoshuJigou}
                  </TableCell>                  <TableCell align="center">
                    {item.chaojiFlag ? <Typography color='info.dark' >超级管理员</Typography> : '普通管理员'}
                  </TableCell>
                  <TableCell align="center">{item.youxiang}</TableCell>
                  <TableCell align="center" name='账号状态'>
                      {zhanghaoStatus(item.zhantingShiyong)}
                  </TableCell>
                  <TableCell align="center" name= '对指定管理员账号操作“启用-停用'>   
                    { (item.shoujiHaoma !== guanliYonghuId) && 
                    <Button onClick={()=>{updateGuanliyuanStatus(item.shoujiHaoma);}}>停用/启用</Button>    
                    }                 
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={zongshu}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}
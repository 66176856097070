import React, { createContext, useState } from "react";

// My Hook
function useLocalStorage(key, initialValue) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
	  try {
		// Get from local storage by key
		const item = window.localStorage.getItem(key);
		// Parse stored json or if none return initialValue
		return item ? JSON.parse(item) : initialValue;
	  } catch (error) {
		// If error also return initialValue
		console.log(error);
		return initialValue;
	  }
	});
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value) => {
	  try {
		// Allow value to be a function so we have same API as useState
		const valueToStore =
		  value instanceof Function ? value(storedValue) : value;
		// Save state
		setStoredValue(valueToStore);
		// Save to local storage
		window.localStorage.setItem(key, JSON.stringify(valueToStore));
	  } catch (error) {
		// A more advanced implementation would handle the error case
		console.log(error);
	  }
	};
	return [storedValue, setValue];
  }

export const MyContext = createContext({});

export const MyContextProvider = props => {
	// Initial values are obtained from the props
	const {
		// users: initialUsers,
		// selectedUser: initialSelectedUsers,
		children,
	} = props;

	// Use State to keep the values
	const [dengluFlag, setDengluFlag] = useLocalStorage('dengluFlag',false);//useState(false);
	const [guanliYonghuId, setGuanliYonghuId] = useLocalStorage('guanliYonghuId','');//useState('');
	const [jiaose, setJiaose] = useLocalStorage('jiaose','');//useState('');
	const [xingming,setXingming] = useLocalStorage('xingming','');//useState('');	
	const [chaojiFlag,setChaojiFlag] = useLocalStorage('jiaose','');//useState(false);
	const [suoshuJigou,setSuoshuJigou] = useLocalStorage('suoshuJigou','');
	const [chaojiTag,setChaojiTag] = useLocalStorage('chaojiFlag','');  // 打补丁，变通处理原有chaojiFlag 值会在页面刷新时，意外改变，造成预期外结果


	const [buxuanJigou, setBuxuanJigou] = useState('其他机构');

	const usersContext = {
		dengluFlag, setDengluFlag,
		guanliYonghuId, setGuanliYonghuId,
		jiaose, setJiaose,
		buxuanJigou, setBuxuanJigou,
		chaojiFlag,setChaojiFlag,
		xingming,setXingming,
		suoshuJigou,setSuoshuJigou,
		chaojiTag,setChaojiTag,   // 打补丁，变通处理原有chaojiFlag 值会在页面刷新时，意外改变，造成预期外结果
	}
	// pass the value in provider and return
	return <MyContext.Provider value={usersContext}>{children}</MyContext.Provider>;
}


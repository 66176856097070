import * as React from 'react';
import {useState} from 'react';

import { Button, TextField, DialogActions, Box,DialogTitle } from '@mui/material';
import {Dialog, Grid,FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import { styled } from '@mui/material/styles';

const quanjuBianliang = require('../changliang/quanju');

const Input = styled('input')({
  display: 'none',
});

export default function AddCepingDialog(props) {
  const { open, setOpen, refreshFunc } = props;
  // const [data, setData] = React.useState(itemData);
  const [imgSrc, setImgSrc] = useState();
  const [imgFile, setImgFile] = useState();

  const [cepingMingcheng,setCepingMingcheng] = useState('');
  const [cepingJianjie,setCepingJianjie] = useState('');
  const [baomingJiezhiRiqi,setBaomingJiezhiRiqi] = useState('');
  const [cepingKaishiRiqi,setCepingKaishiRiqi] = useState('');
  const [cepingNian,setCepingNian] = useState(new Date(Date.now()+30 * 24 * 60 * 60 * 1000).getFullYear());
  
  const [tishi,setTishi] = useState({
    cepingMingchengFlag: false,
    cepingMingchengTishi:"测评活动名称至少2个字符",
  });

  const checkShuru = () => {
    var cepingMingchengFlag = cepingMingcheng.length<2;

    setTishi({...tishi,
      cepingMingchengFlag,
    });

    if(!cepingMingchengFlag)
      return true;
    else
      return false;
  }

  function handleQueren() {
    if(!checkShuru()) return false; 

    //提交更新请求到后台
    gengxinItemData();
    refreshFunc();
    setOpen(false);
    setImgSrc(null);
  }

  function handleQuxiao() {
    setOpen(false);
    setImgSrc(null);
  }

  const gengxinItemData = () => {
    let formData = new FormData();
    // formData.append("cepingId",itemData.cepingId);
    //用来为后台提供一个临时保存的唯一文件名，由于cepingId是动态生成
    formData.append("reqTime",Date.now());
    formData.append("cepingNian",cepingNian);  
    formData.append("cepingMingcheng",cepingMingcheng);
    formData.append("cepingJianjie",cepingJianjie);
    formData.append("baomingJiezhiRiqi",baomingJiezhiRiqi||'');
    formData.append("cepingKaishiRiqi",cepingKaishiRiqi||'');
    formData.append("cepingHaibao", imgFile);
    axios.post(quanjuBianliang.houtaiLujing + "/api/guanliyuan/cepingDataUpload", 
      formData, {
        withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      }      
    }).then((response)=>{
      console.log(response.data);
    },(err)=>{console.log(err);});
  }


return (
  <Dialog open={open} fullWidth={true} maxWidth='md'>
    <DialogTitle>新建测评活动</DialogTitle>
    <Box sx={{ mx: 4, mt: 0, display:'flex', flexDirection:'row'}}>
      <Grid sx={{display:'flex',flexDirection:'column'}}>
        <img id='cepingHaibao' alt='测评活动海报' height={200} src={imgSrc} />
        <label htmlFor="contained-button-file">
          <Input id="contained-button-file" accept=".jpg" name="cepingHaibao" type="file"
            onChange={evt => {
              const [file] = evt.target.files;
              if (file) {
                setImgFile(file);
                setImgSrc(URL.createObjectURL(file));
              }
            }} />
          <Button component="span">
            上传海报
          </Button>
        </label>
      </Grid>

      <Grid item xs sx={{ml:4,mr:2}}>      
      
      <TextField size='small' sx={{ mb: 1, width: '100%',}} variant="outlined"
            value={cepingMingcheng} onChange={(e) => setCepingMingcheng(e.target.value)} label="测评名称" 
            error={tishi.cepingMingchengFlag}
            helperText = {tishi.cepingMingchengFlag&&tishi.cepingMingchengTishi}
            />
      <Grid item sx={{display:'flex', flexDirection:'row',mt:1}}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">测评年</InputLabel>
            <Select size='small' 
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cepingNian}
              label="测评年"
              onChange={(e)=>setCepingNian(e.target.value)}
            >
              <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
              <MenuItem value={new Date().getFullYear()+1}>{new Date().getFullYear()+1}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} sx={{mx:1}}>
          <TextField
            sx={{ width: '100%',}}
            id="date1"
            label="报名截止日期"
            type="date"        
            value={baomingJiezhiRiqi}
            onChange={(e) => { setBaomingJiezhiRiqi(e.target.value) }}           
            size="small"
            InputLabelProps={{shrink: true,}}
          />
        </Grid>
        <Grid item xs={5}>
        <TextField
          sx={{ width: '100%'}}
          id="date2"
          label="测评开始日期"
          type="date"        
          value={cepingKaishiRiqi}
          onChange={(e) => { setCepingKaishiRiqi(e.target.value) }}          
          size="small"
          InputLabelProps={{shrink: true,}}
        />
        </Grid>
      </Grid>
      
      <TextField sx={{ my: 2, width: '100%', minWidth: '350px' }} multiline rows={4}
        value={cepingJianjie} 
        onChange={(e) => { setCepingJianjie(e.target.value) }} label="测评简介" variant="outlined" />
      </Grid>
    </Box>
    <DialogActions>
      <Button variant='outlined' onClick={handleQueren}>确认添加</Button>
      <Button variant='outlined' onClick={handleQuxiao}>取消</Button>
    </DialogActions>
  </Dialog>
);
}



import * as React from 'react';
import { useEffect, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material';

import { CssBaseline, Container, Typography, TablePagination } from '@mui/material';

import { useNavigate } from "react-router-dom"

import { MyContext } from "../context";

import axios from 'axios';

import Copyright from '../copyright';
const quanjuBianliang = require('../changliang/quanju');

export default function JigouXueyuan() {
    const dataTitle = '本机构学员';
    //后台数据访问接口
    const backDataUrl = '/api/guanliyuan/suoyouXueyuan';
    
  const myContext = React.useContext(MyContext);
  const { setDengluFlag, } = myContext;
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);
  
  const [shujuList, setShujuList] = useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //加载我的订单
  useEffect(() => {
    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      {page,rowsPerPage},
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          console.log('获取'+dataTitle+' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page,rowsPerPage]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>        

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650,'td, th': { border: 1,borderColor:'grey.400' }}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">姓名</TableCell>
                <TableCell align="center">手机号码</TableCell>
                <TableCell align="center">身份证号码</TableCell>
                <TableCell align="center">电子邮箱</TableCell>                
                <TableCell align="center">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{border: 1,borderColor:'grey.400'}}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1,borderColor:'grey.400' } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {item.xingming}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {item.shoujiHaoma}
                  </TableCell>
                  <TableCell align="center">                                       
                    {item.shenfenzhengHaoma}
                  </TableCell>
                  <TableCell align="center">{item.youxiang}</TableCell>                  
                  <TableCell align="center">
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={zongshu}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Copyright sx={{ mt: 10 }} />
      </Container>
    </React.Fragment>
  );
}
import * as React from 'react';
import { useEffect, useState } from 'react';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, Grid} from '@mui/material';

import { CssBaseline, Container, Typography, TablePagination, Button,} from '@mui/material';

import { useNavigate } from "react-router-dom"

import { MyContext } from "../context";

import axios from 'axios';

import Copyright from '../copyright';
import EditCepingDialog from '../zujian/editCepingDialog';
import GuanLianKemuDialog from '../zujian/guanlianKemuDialog';
import AddCepingDialog from '../zujian/addCepingDialog';

const quanjuBianliang = require('../changliang/quanju');

export default function SuoyouCeping() {
    const dataTitle = '测评活动列表';
    //后台数据访问接口
    const backDataUrl = '/api/guanliyuan/suoyouCeping';
    
  const myContext = React.useContext(MyContext);
  const { setDengluFlag, } = myContext;
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [zongshu, setZongshu] = React.useState(0);  
  const [shujuList, setShujuList] = useState([]);

  const [refreshCount,setRefreshCount] = useState(0);
  const [showJibenXinxiDialog,setShowJibenXinxiDialog] = useState(false);
  const [showGuanlianKemuDialog,setShowGuanlianKemuDialog] = useState(false);
  const [showAddCepingDialog, setShowAddCepingDialog] = useState(false);

  const [itemData,setItemData] = useState();
  const [clickedCepingId,setClickedCepingId] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //加载我的订单
  useEffect(() => {
    axios.post(quanjuBianliang.houtaiLujing + backDataUrl,
      {page,rowsPerPage},
      { withCredentials: true })
      .then((response) => {
        //用户未登录
        if (response.data.status === 1) {
          console.log('获取'+dataTitle+' 数据成功！');
          setShujuList(response.data.shuju);
          setZongshu(response.data.zongshu);
        } else if (response.data.status === -1) {
          console.log('用户未登录');
          setDengluFlag(false);
          navigate("/backend/denglu");
        } else {
          console.log('服务器错误:' + response.data.message);
        }
        console.log(response.data);
      }, (err) => {
        console.log(err);
      });

  }, [page,rowsPerPage,refreshCount]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {dataTitle}
        </Typography>

        <Grid container sx={{justifyContent:'flex-end'}}>
          <Button variant='outlined' sx={{ mb: 2 }} onClick={() =>setShowAddCepingDialog(true)} 
            >新建测评活动</Button>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650,'td, th': { border: 1,borderColor:'grey.400' }}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">测评活动名称</TableCell>
                <TableCell align="center">活动海报</TableCell>
                <TableCell align="center">测评简介</TableCell>
                <TableCell align="center">报名截止日期</TableCell>                
                <TableCell align="center">开始日期</TableCell>
                <TableCell align="center">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{border: 1,borderColor:'grey.400'}}>
              {shujuList && shujuList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ 'td, th': { border: 1,borderColor:'grey.400' } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {item.cepingMingcheng}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <img alt={item.id} height="120" src={`/res/ceping/${item.cepingId}.jpg?t=${Date.now()}`} />
                  </TableCell>
                  <TableCell align="left" style={{ width: '35%' }}>                                       
                    {item.cepingJianjie}
                  </TableCell>
                  <TableCell align="center">{item.baomingJiezhiRiqi}</TableCell>                  
                  <TableCell align="center">
                    {item.cepingKaishiRiqi}
                  </TableCell>
                  <TableCell align="center">
                    <Grid sx={{display:'flex',flexDirection:'column'}}>
                    <Button variant='outlined' onClick={()=>{setItemData(item);setShowJibenXinxiDialog(true);}}>基本信息</Button>
                    <Button variant='outlined' onClick={()=>{setClickedCepingId(item.cepingId);setShowGuanlianKemuDialog(true);}} sx={{mt:1}}>关联科目</Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              <EditCepingDialog open={showJibenXinxiDialog} setOpen={setShowJibenXinxiDialog} itemData={itemData} 
                setItemData={setItemData}  refreshFunc={()=>setRefreshCount((pre)=>pre+1)} />

              {clickedCepingId && <GuanLianKemuDialog open={showGuanlianKemuDialog} 
                setShowGuanlianKemuDialog={setShowGuanlianKemuDialog} cepingId={clickedCepingId}/>
              }

              <AddCepingDialog title='添加机构' open={showAddCepingDialog}
                setOpen={setShowAddCepingDialog} 
                refreshFunc={()=>setRefreshCount((pre)=>pre+1)}
              />
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          labelRowsPerPage='每页数据:'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={zongshu}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
}
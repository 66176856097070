import * as React from 'react';
import {useState} from 'react';

import { Button, TextField, DialogActions, Box, Grid, Typography,} from '@mui/material';
import { DialogTitle, FormControl, Dialog, InputLabel, Select, MenuItem} from '@mui/material';

import axios from 'axios';

import { styled } from '@mui/material/styles';

const quanjuBianliang = require('../changliang/quanju');

const Input = styled('input')({
  display: 'none',
});

export default function AddKemuDialog(props) {
  const { open, setOpen, refreshFunc } = props;
  // const [data, setData] = React.useState(itemData);
  const [imgSrc, setImgSrc] = useState();
  const [imgFile, setImgFile] = useState();

  const [kemuJieshao,setKemuJieshao] = useState('');
  const [kemuMingcheng,setKemuMingcheng] = useState('');
  const [dengjiShuliang,setDengjiShuliang] = useState(3);
  const [jiage,setJiage] = useState(300);
  const [suoshuMokuai,setSuoshuMokuai] = useState('程序设计');

  const [tishi,setTishi] = useState({
    kemuMingchengFlag: false,
    kemuMingchengTishi:"科目名称至少2个字符",
  });

  const checkShuru = () => {
    var kemuMingchengFlag = kemuMingcheng.length<2;

    setTishi({...tishi,
      kemuMingchengFlag,
    });

    if(!kemuMingchengFlag)
      return true;
    else
      return false;
  }

  function handleQueren() {
    if(!checkShuru()) return false; 
    //提交更新请求到后台
    gengxinKemu();

    refreshFunc();
    setOpen(false);
    setImgSrc(null);
  }

  function handleQuxiao() {
    setOpen(false);
    setImgSrc(null);
  }

  const gengxinKemu = () => {

    let formData = new FormData();
    formData.append("kemuJieshao",kemuJieshao);
    formData.append("kemuMingcheng",kemuMingcheng);
    formData.append("dengjiShuliang",dengjiShuliang);
    formData.append("jiage",jiage);
    formData.append("suoshuMokuai",suoshuMokuai);

    formData.append("kemuTubiao", imgFile);
    axios.post(quanjuBianliang.houtaiLujing + "/api/guanliyuan/kemuDataUpload", 
      formData, {
        withCredentials: true,
        headers: {"Content-Type": "multipart/form-data",}      
    }).then((response)=>{
      console.log(response.data);
    },(err)=>{console.log(err);});    
  }

return (
  <Dialog open={open}>
    <DialogTitle>新建科目信息</DialogTitle>
      <Box sx={{ ml: 4, mt: 0, display:'flex', flexDirection:'row' }}>
        <Grid sx={{display:'flex',flexDirection:'column'}}>
          <img id='kemuTubiao' alt='科目图标' height={120} width={100} src={imgSrc} />
          <label htmlFor="contained-button-file">
            <Input id="contained-button-file" accept=".svg" name="kemuTubiao" type="file"
              onChange={evt => {
                const [file] = evt.target.files;
                if (file) {
                  setImgFile(file);
                  setImgSrc(URL.createObjectURL(file));
                }
              }} />
            <Button component="span">
              上传图标
            </Button>      
            
          </label>
        </Grid>
        <Grid xs sx={{ml:2,mr:2}}>
          <TextField size='small' sx={{ mb: 1, width: '100%',}} variant="outlined"
            value={kemuMingcheng} onChange={(e) => setKemuMingcheng(e.target.value)} label="科目名称" 
            error={tishi.kemuMingchengFlag}
            helperText = {tishi.kemuMingchengFlag&&tishi.kemuMingchengTishi}
            />
          <Grid item sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}> 
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">等级数量</InputLabel>
                <Select size='small' 
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dengjiShuliang}
                  label="等级数量"
                  onChange={(e)=>setDengjiShuliang(e.target.value)}
                >
                  <MenuItem value={1}>一级</MenuItem>
                  <MenuItem value={2}>二级</MenuItem>
                  <MenuItem value={3}>三级</MenuItem>
                  <MenuItem value={4}>四级</MenuItem>
                  <MenuItem value={5}>五级</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ml:1}}>
              <TextField size='small' variant="outlined" type="number"
                value={jiage} onChange={(e) => setJiage(e.target.value)} label="科目价格" />          
            </Grid> 
            <Grid item xs={4} sx={{ml:1}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">所属模块</InputLabel>
                <Select size='small' 
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={suoshuMokuai}
                  label="所属模块"
                  onChange={(e)=>setSuoshuMokuai(e.target.value)}
                >
                  <MenuItem value={'程序设计'}>程序设计</MenuItem>
                  <MenuItem value={'感知控制'}>感知控制</MenuItem>
                  <MenuItem value={'产品结构'}>产品结构</MenuItem>
                  <MenuItem value={'算法基础'}>算法基础</MenuItem>
                  <MenuItem value={'应用领域'}>应用领域</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TextField size='small' sx={{ width: '100%',}} multiline rows={3}
            value={kemuJieshao} onChange={(e) => setKemuJieshao(e.target.value)} label="科目介绍" variant="outlined" />
        </Grid>
      </Box>
      <Box sx={{ ml: 2, mt:1, fontWeight: 'medium', fontSize:8,}}>
        *科目名称/等级/价格请认真确认，被学员订购后无法更改; 图标请使用svg格式文件</Box>
    <DialogActions>
      <Button variant='outlined' onClick={handleQueren}>确认添加</Button>
      <Button variant='outlined' onClick={handleQuxiao}>取消</Button>
    </DialogActions>
  </Dialog>
);
}


